import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import postitem from './components/dashboard/items_post/item_post.vue'
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
	load: {
		key: "AIzaSyAOgSfRIRRSBmEyg6264vn4uUvS6wsILc0",
		libraries: "places"
	}
});



Vue.component('item-post', postitem)
Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
