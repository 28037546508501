var enc = require('simple-encryptor');
var _KPA = '2vb17oDC7bWdvITERXqi2ynpcSEbnIg9fFI68Qe4';
var _TOKEN = '';
import util from './../util.js';

var _ENC = null;
var _departamentos = [
     "Argentina",
     "Argentina:Mendoza",
     "Argentina:Mendoza:Godoy Cruz",
     "Argentina:Mendoza:Ciudad",
     "Argentina:Mendoza:Guaymallén",
     "Argentina:Mendoza:Las Heras",
     "Argentina:Mendoza:Maipú",
     "Argentina:Mendoza:Luján",
     "Argentina:Mendoza:San Rafael",
     "Argentina:Mendoza:General Alvear",
     "Argentina:Mendoza:Malargue",
     "Argentina:Mendoza:Tunuyán",
     "Argentina:Mendoza:Tupungato",
     "Argentina:Mendoza:San Carlos",
     "Argentina:Mendoza:Junín",
     "Argentina:Mendoza:Santa Rosa",
     "Argentina:Mendoza:La Paz",
     "Argentina:Mendoza:San Martín",
     "Argentina:Mendoza:Rivadavia",
     "Argentina:Mendoza:Lavalle"
].sort();


const __VARS = [252, 441, 349, 119, 151, 269, 58, 399, 416, 329, 147, 465, 433, 222, 121, 84, 353, 438, 281, 407, 373, 120, 348, 71, 56, 362, 266, 183, 447, 292];
const __JT_BO = __VARS[14];
const __AR_XD = Math.ceil(__JT_BO / 5);

//postDatos,postOpinion,postSugerencia,postProyecto,postRevision,postOpcion}

var _assocList = {
     "Datos" : {
          mod:'postDatos',
          Title : 'Datos',
          Text : 'Estadística, Gráficos, Videos, Información corroborable, Fuentes. Fundamental para poder dar el debate con información correctamente clasificada.',
     },
     "Opinión" : {
          Title : 'Opinión',
          Text : 'Enciende la chispa, armá polémica, opina sobre un tema en particular.',
     },
     "Sugerencia" : {
          Title : 'Sugerencia',
          Text : 'Decinos que te parece que podríamos mejorar dentro de nuestras filas libertarias.',
     },
     "Proyecto" : {
          Title : 'Proyecto',
          Text : 'Arma un proyecto que de lugar a revisiones continuas por otros usuarios',
     },
     "Revisión" : {
          Title : 'Revisión',
          Text : 'Corrige o agrega elementos a un proyecto',
     },
     "Elección" : {
          Title : 'Elección',
          Text : 'Configura una elección.',
     },
     "Opción" : {
          Title : 'Opciones / Candidatos',
          Text : 'Opción de una votación, agrega toda las bondades de esta opción para ser seleccionada',
     },
};



//import $ from 'jquery'
const URL_POST_LIKE = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/post/like';
const URL_POST_DISLIKE = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/post/dislike';
const URL_FILTER_POST = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/post/filter';
const URL_POST = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/post';
const URL_POST_RATING = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/post/rating';
const URL_CAT = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/cats';



const state = {
     posts: [],
     parents: [],
     main: [],
     categorias: [],
     post: {},
     token: '',
     departamentos:_departamentos,
     isExistUser: 0,
     types:_assocList
};

const getters = {
     getParents(state) {
          return state.parents.map(p=>{
               return {
                    ...p,
                    ans:[],
                    ans_post:{},
               }
               
          });
     },
     getMain(state) {
          return state.main.map(p=>{
               return {
                    ...p,
                    ans:[],
                    ans_post:{},
               }
               
          });
     },
     getPost(state) {
          console.log('state.main.length',state.main.length);
          if (state.main.length > 0){
               return state.posts.map(p=>{
                    return {
                         ...p,
                         ans:[],
                         ans_post:{},
                    }
                    
               });
          }
          return state.posts;
     },
     getDepartamentos(state) {
          return state.departamentos.map(d=>{
               
               var text = d.split(":").join(" - ");
               return {
                    text : text,
                    value : d,
                    disabled : false,
               }
          });
     },
     getCategories(state) {
          console.log('state.categorias',state.categorias);
          return state.categorias;
     },
     isExistUser() {
          return state.isExistUser;
     },
     getPostTypes(state){
          return state.types;
     }
}


const mutations = {

     logged(state, logged) {
          var val = new Date().getTime();
          state.isExistUser = logged ? val : val * -1;
     },
     saved() {

     },
     confirmCode() {
     },
     setToken(state, token) {
          state.token = token;
     },
     sendedCode() {

     },
     setCategories(state,categorias) {
          state.categorias = categorias;
     },
     setPosts(state,posts) {
          state.posts = posts;
     },
     setPostsParents(state,posts) {
          state.parents = posts;
     },
     setPostMain(state,main) {
          state.main = main;
     },

}

const actions = {
    
  
     init: async(context)=>{
          var c = util.getCookie('init_');
          console.log('c',c);
          if (c.length > 10) {
               var res = JSON.parse(c);
               var afiliado_ocupacion = res.key.substr(__JT_BO, __AR_XD)
               _ENC = enc(afiliado_ocupacion);
               _TOKEN = res.token;
               console.log('_TOKEN',_TOKEN);
               return;
          }
          var url = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/register';

          var sendata = {
               "_a": "l1rc",
               "_77qid82": "kAR",
               "_9rrg": "QJFf0d"
          }


          console.log('sendinit',url, sendata);
          var res = await sendInit(url, sendata);
          if (res.token == 'forbidden') {
               context.commit('setToken', res.token);
          }
          else {
               console.log("guarda cookie");
               console.log('res.key', res.key);
               console.log('res.token', res.token);
               util.setCookie('init_', JSON.stringify(res))

               console.log('res', res)
               _TOKEN = res.token;
               var afiliado_ocupacion = res.key.substr(__JT_BO, __AR_XD)

               _ENC = enc(afiliado_ocupacion);

          }
     },
     save: async(context,post)=>{
          console.log('postsend save',post);
          var res = await sendEnc(URL_POST, post);
          context.commit('saved');
     },
     addRating: async(context,{rating,post,user})=>{
          var res = await sendEnc(URL_POST_RATING, {post_id:post.id,rating,user_dni:user});
          console.log('res',res);
     },
     fetchCategorias: async(context)=>{
          console.log('llega por aca');
          var cats = await sendEnc(URL_CAT,{});
          console.log('llega por aca 2');
          context.commit('setCategories',cats['result']);
     },
     fetchPostFilter: async(context,filter)=>{
          console.log('llega por aca');
          var result = await sendEnc(URL_FILTER_POST,filter);
          console.log('llega por aca 2',result);
          context.commit('setPosts',result['result']['children']);
          context.commit('setPostMain',result['result']['main']);
          context.commit('setPostsParents',result['result']['parents']);
     },
     
     
}



export default {
     namespaced: true,
     state, getters, actions, mutations
}





function sendInit(url, data) {
     var senddata = JSON.stringify(data);
     /*return $.ajax({
          url: url,
          type: 'post',
          headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
               'x-api-key': _KPA
          },
          crossDomain: true,
          contentType: 'application/json; charset=UTF-8',
          dataType: "json",
          data: senddata
     });

     var senddata = _ENC.encrypt(JSON.stringify(data));*/
     console.log('senddata', url, senddata);
     return ajaxPost(url, senddata, {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-api-key': _KPA
     });
}

async function sendEnc(url, data) {
     var senddata = _ENC.encrypt(JSON.stringify(data));
     try{
          var res =   await ajaxPost(url, senddata, {
               'Content-Type': 'application/x-www-form-urlencoded',
               'Authorization': _TOKEN
          });
          console.log('sendEnc',res);
          if (res['err'].length > 3){
               throw(res['err']);
          }
          return res;
          
     }
     catch(e){
          console.log('e',e);
          setTimeout(function(){
               return sendEnc(url, data)
          },3000)
          
     }
     
}

function fetch(url, data, vars) {
     /*
          vars = vars || {};
          for (var i in vars) {
               url = url.replace(':' + i, vars[i])
          }
     
          console.log('url', url);
     
          return $.ajax({
               url: url,
               type: "get",
               data: data,
               crossDomain: true,
     
          });*/
}


function ajaxPost(url, data, header) {

     return new Promise(function (res, rej) {
          header = header || {};
          var request = new XMLHttpRequest();

          request.open('POST', url, true);



          for (var i in header) {
               request.setRequestHeader(i, header[i]);
          }

          request.onload = function () {
               if (this.status >= 200 && this.status < 400) {
                    // Success!
                    var resp = JSON.parse(this.response);
               } else {
                    // We reached our target server, but it returned an error
               }
               res(JSON.parse(this.response));
          };

          request.onerror = function (e) {
               // There was a connection error of some sort

               //no autorizado
               rej(e)
          };
          request.send(data);
     })



}