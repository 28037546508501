<template>
	<div>
		<afiliados-add v-if="dialog=='afiliado_add'" :data="afiliado_edit" @close="closeAddAfiliado" />
		<afil v-if="afiliado" :afiliado="afiliado" @close="closeAfiliado" />
		<afil-datos v-if="datos" :afiliado="datos" @close="datos = false" @editar="editar" />
		<enviar v-if="penviar" :afiliado="penviar" @close="penviar = false" />
		<pic v-if="item_pic_selected" :item="item_pic_selected" @close="item_pic_selected=false" />

		<v-simple-table fixed-header style="padding-bottom:95px;">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left" colspan="8">
							<span class="pageLabel">Paginas:</span>
							<span
								class="page-items"
								v-for="(pageList,i) in getPages"
								:key="i"
								@click="changePage(pageList)"
							>
								<span class="page-list" :class="{selectedPage:pageList.page==page}">{{pageList.page+1}}</span>
								<span class="page-count">{{pageList.count>0?"("+pageList.count+")":''}}</span>
							</span>
						</th>
					</tr>
					<tr>
						<th class="text-left">Sel</th>
						<th class="text-left">
							<v-btn text>Nombre</v-btn>
						</th>
						<th class="text-left">
							<v-btn text>Dni</v-btn>
						</th>
						<th class="text-left">
							<v-btn text>Genero</v-btn>
						</th>
						<th class="text-left">
							<v-btn text>localidad</v-btn>
						</th>
						<th class="text-left">
							<v-btn text>direccion</v-btn>
						</th>
						<th class="text-left">
							<v-btn text>Adh</v-btn>
						</th>
						<th class="text-left">
							<v-btn text>Afil</v-btn>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item,i) in getAfiliadosPerPage"
						:class="{
                                             red:item.colaboracion=='datos falsos/descartar',
                                             'green-text':item.ficha
                                        }"
						:key="i"
					>
						<td>
							<div @click="checkItem(item)" :class="{selected:selected[item.dni]}" class="dw-checkbox">
								<div class="dw-check"></div>
							</div>
						</td>
						<td>
							<v-btn :color="item.ficha?'green' : 'blue'" text small v-on:click="afiliado=item">
								<v-icon
									small
									color="yellow"
									v-if="item.usuario_id.length > 0 && item.usuario_id!=usuario_logged"
								>mdi-share-circle</v-icon>
								<v-icon small color="green" v-if="item.ficha_firma">mdi-check-decagram</v-icon>
								{{ item.apellido + ", "+item.nombre }}
							</v-btn>
						</td>

						<td>{{ item.dni }}</td>
						<td>{{ item.sexo }}</td>
						<td>{{ item.localidad }}</td>
						<td>{{ item.data.calle }} {{ item.data.numero }} {{ item.data.piso ? item.data.piso : '' }} {{ item.data.depto ? item.data.depto :'' }}</td>
						<td>{{item.adhesion_estado}}</td>
						<td>{{item.afiliacion_estado}}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<v-dialog persistent v-model="cestado" width="500" transition="dialog-bottom-transition">
			<v-card outlined>
				<v-card-title>Cambiar estado de fichas</v-card-title>
				<v-card-text>
					<v-container>
						<v-card outlined>
							<v-card-text>
								<v-checkbox v-model="m_adh" label="Modificar estado adhesiones"></v-checkbox>
								<div v-if="m_adh">
									<label>Adhesiones</label>
									<v-autocomplete
										outlined
										dense
										v-model="ad_estado"
										:items="['Sin procesar','En Proceso','Cargadas','No válido']"
									/>
								</div>
							</v-card-text>
						</v-card>
						<v-card outlined>
							<v-card-text>
								<v-checkbox v-model="m_af" label="Modificar estado afiliaciones"></v-checkbox>
								<div v-if="m_af">
									<label>Afiliacines</label>
									<v-autocomplete
										outlined
										dense
										v-model="af_estado"
										:items="['Sin procesar','En Proceso','Cargadas','No válido']"
									/>
								</div>
							</v-card-text>
						</v-card>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn @click="actualizarEstado" color="primary">Guardar</v-btn>
					<v-btn text @click="cestado=false" color="primary">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog persistent v-model="info" width="500" transition="dialog-bottom-transition">
			<v-card color="#1A237E">
				<v-card-title>FILTROS</v-card-title>
				<v-card-text>
					<v-simple-table dense dark style="background : #1A237E">
						<template slot:default>
							<tr>
								<td>Total</td>
								<td>
									<v-checkbox
										dense
										:label=" '(' + count.total + ') '"
										@change="changeFilter('total')"
										v-model="f_total"
									/>
								</td>
							</tr>
							<tr>
								<td>Adhesiones</td>
								<td>
									<v-radio-group v-model="filter.estado_ad" dense>
										<v-radio :label="'Todas'" :value="-1"></v-radio>
										<v-radio :label="'Sin procesar ('+count.sinprocesar_ad +')'" :value="'Sin procesar'"></v-radio>
										<v-radio :label="'En Proceso ('+count.enproceso_ad +')'" :value="'En Proceso'"></v-radio>
										<v-radio :label="'Cargadas ('+count.cargadas_ad +')'" :value="'Cargadas'"></v-radio>
										<v-radio :label="'Rechazadas ('+count.rechazadas_ad +')'" :value="'No válido'"></v-radio>
									</v-radio-group>
								</td>
							</tr>
							<tr>
								<td>Afiliaciones</td>
								<td>
									<v-radio-group v-model="filter.estado_af" dense>
										<v-radio :label="'Todas'" :value="-1"></v-radio>
										<v-radio :label="'Sin procesar ('+count.sinprocesar_af +')'" :value="'Sin procesar'"></v-radio>
										<v-radio :label="'En Proceso ('+count.enproceso_af +')'" :value="'En Proceso'"></v-radio>
										<v-radio :label="'Cargadas ('+count.cargadas_af +')'" :value="'Cargadas'"></v-radio>
										<v-radio :label="'Rechazadas ('+count.rechazadas_af +')'" :value="'No válido'"></v-radio>
									</v-radio-group>
								</td>
							</tr>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="info=false" color="primary">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-btn class="change-button" color="green" dark fixed top right small fab @click="changeEstado">
			<v-icon>mdi-swap-horizontal</v-icon>
		</v-btn>
		<v-btn class="add-button" color="pink" dark fixed top right small fab @click="addNew">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-btn class="info-button" color="blue" dark fixed top right small fab @click="info=true">
			<v-icon>mdi-filter</v-icon>
		</v-btn>
	</div>
</template>

<script>
/*

count.enproceso_af
count.cargadas_af
count.rechazadas_af

count.enproceso_ad
count.cargadas_ad
count.rechazadas_ad

*/
import afiliadosAdd from "./afiliado_form_add.vue";
import afilDatos from "./datos.vue";
import afil from "./afil.vue";
import enviar from "./enviar.vue";
import pic from "./pic.vue";

export default {
	components: { afil, afilDatos, afiliadosAdd, enviar, pic },

	props: {
		dni: {
			default: ""
		},
		departamento: {
			default: ""
		},
		assigned: {
			default: false
		}
	},

	computed: {
		getPages() {
			var afLength = this.afiliados.length;
			var count = afLength / this.limitxpage;
			var pages = [];
			for (var i = 0; i < count; i++) {
				var afiliadosPage = this.afiliados.slice(
					i * this.limitxpage,
					i * this.limitxpage + this.limitxpage
				);
				var coutafil = 0;
				for (
					var t = 0, countAfiliadosPage = afiliadosPage.length;
					t < countAfiliadosPage;
					t++
				) {
					if (
						typeof this.selected[afiliadosPage[t]["dni"]] ===
						"undefined"
					) {
						continue;
					}
					if (this.selected[afiliadosPage[t]["dni"]]) {
						coutafil++;
					}
				}

				pages.push({
					count: coutafil,
					page: i
				});
			}
			console.log("pages", pages);
			return pages;
		},
		getAfiliadosPerPage() {
			return this.afiliados.slice(
				this.page * this.limitxpage,
				this.page * this.limitxpage + this.limitxpage
			);
		}
	},
	watch: {
		assigned: {
			handler(val) {
				console.log("assigned", val);
				this.init();
			},
			immediate: true
		},
		departamento(val) {
			this.filtered(val);
			this.drawer = false;
		},
		dni(val) {
			this.filtered(this.departamento);
		},
		filter: {
			handler(actual, prev) {
				if (actual.estado_ad == -1 || actual.estado_af == -1) {
					this.f_total = true;
				}
				if (actual.estado_ad != -1 || actual.estado_af != -1) {
					this.f_total = false;
				}
				this.filtered(this.departamento);
			},
			deep: true
		}
	},

	data() {
		return {
			page: 0,
			limitxpage: 100,

			af_estado: "",
			ad_estado: "",

			f_disabled: true,
			f_total: true,
			filter: {
				estado_ad: -1,
				estado_af: -1,
			},
			count: {
				sinprocesar_ad: 0,
				enproceso_ad: 0,
				cargadas_ad: 0,
				rechazadas_ad: 0,
				sinprocesar_af: 0,
				enproceso_af: 0,
				cargadas_af: 0,
				rechazadas_af: 0,
				total: 0
			},

			cestado: false,
			info: false,
			dialog: false,
			datos: false,
			penviar: false,
			dato: false,
			penviar: false,

			m_adh: false,
			m_af: false,

			sort: false,
			afiliados: [],
			afiliado: false,
			item_pic_selected: false,
			b_assigned_items: false,
			afiliado_edit: false,
			usuario_logged: "",
			level: 0,
			selected: {}
		};
	},
	mounted() {
		console.log("mounted init");
		//	this.init();
	},
	methods: {
		changePage(pag) {
			this.page = pag.page;
		},
		async actualizarEstado(item) {
			this.$store.commit("iface/setWait", { wait: true });

			var dnis = Object.keys(this.selected);

			var send = {
				dnis: dnis,
				update_ad: 0,
				update_af: 0
			};
			if (this.m_adh) {
				send["update_ad"] = this.ad_estado;
			}
			if (this.m_af) {
				send["update_af"] = this.af_estado;
			}

			await this.$store.dispatch(
				"afiliaciones/changeEstadoFichas",
				send
			);

			this.cestado = false;
			this.$store.commit("iface/setWait", { wait: false });
		},
		changeEstado(item) {
			this.cestado = true;
		},
		checkItem(item) {
			this.$set(this.selected, item.dni, !this.selected[item.dni]);
		},
		editar(datos) {
			var datos = JSON.parse(datos);
			this.datos = false;
			this.openAfiliadoAdd();
			console.log("editar", datos);
			this.afiliado_edit = datos;
		},
		changeFilter(key) {
			if (key == "habilitados") {
				if (this.f_disabled) {
				}
			}
			if (key == "total") {
				if (this.f_total) {
					this.filter = {
						estado_ad: -1,
						estado_af: -1,
					};
				} else {
				}
			}
			this.filtered(this.departamento);
		},
		async init() {
			var permisos = this.$store.state.afiliaciones.permisos;
			this.b_assigned_items = this.assigned;
			this.usuario_logged = this.$store.state.afiliaciones.userds;
			this.level = permisos.level;

			await this.reload();
			this.afiliados = this.$store.state.afiliaciones.afiliaciones;
			this.filtered(this.departamento);
		},
		sortAction(keysort) {
			var sort = keysort;
			this.sort = keysort;
			this.afiliados.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
		},
		openAfiliadoAdd() {
			this.prev_modulo = this.modulo;
			this.dialog = "afiliado_add";
			this.pldistmap = false;
		},
		async closeAddAfiliado(reload) {
			if (reload) {
				await this.reload();
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
				this.afiliado = this.afiliados[this.afiliados.length - 1];

				this.filtered(this.departamento);
			}

			this.dialog = "";
		},
		addNew() {
			this.openAfiliadoAdd();
		},
		async reload() {
			await this.$store.commit("iface/setWait", { wait: true });
			await this.$store.dispatch("afiliaciones/fetchAfiliaciones", {
				cargas: 1
			});

			await this.$store.commit("iface/setWait", { wait: false });
			return true;
		},

		async filtered(departamento) {
			var sort = this.sort || false;
			if (this.b_assigned_items > 0) {
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
			}
			if (this.b_assigned_items < 0) {
				this.afiliados = this.$store.state.afiliaciones.afiliaciones.sort(
					(x, y) => (x["id"] > y["id"] ? -1 : 1)
				);
			}
			if (!this.b_assigned_items) {
				this.afiliados = this.$store.state.afiliaciones.afiliaciones.filter(
					a => a.departamento_vive == departamento
				);
			}

			this.count = {
				total: 0,
				cargadas_ad: 0,
				cargadas_af: 0,
				sinprocesar_ad: 0,
				enproceso_ad: 0,
				sinprocesar_af: 0,
				enproceso_af: 0,
				rechazadas_ad: 0,
				rechazadas_af: 0,
			};

			for (var i = 0; i < this.afiliados.length; i++) {
                    //'Sin procesar','En Proceso','Cargadas','No válido'
                    switch(this.afiliados[i].adhesion_estado){
                         case 'Sin procesar':
                              this.count.sinprocesar_ad++;
                         break;
                         case 'En Proceso':
                              this.count.enproceso_ad++;
                         break;
                         case 'Cargadas':
                              console.log('cargadas');
                              this.count.cargadas_ad++;
                         break;
                         case 'No válido':
                              this.count.rechazadas_ad++;
                         break;
                    }
				
                    switch(this.afiliados[i].afiliacion_estado){
                         case 'Sin procesar':
                              this.count.sinprocesar_af++;
                         break;
                         case 'En Proceso':
                              this.count.enproceso_af++;
                         break;
                         case 'Cargadas':
                              this.count.cargadas_af++;
                         break;
                         case 'No válido':
                              this.count.rechazadas_af++;
                         break;
                    }
				

                    
                    try{
                         this.afiliados[i].data = JSON.parse(this.afiliados[i].data);
                    }
                    catch(e){

                    }
				
				if (typeof this.selected[["dni"]] !== "undefined") {
					this.selected[["dni"]] = false;
				}

				this.count.total++;
			}

			var filterAssoc = JSON.parse(JSON.stringify(this.filter));

			var _this = this;

			this.afiliados = this.afiliados.filter(a => {
				
                    
                    if (filterAssoc["estado_ad"]==-1 ){
                         return true;
                    }
                    if (filterAssoc["estado_af"]==-1 ){
                         return true;
                    }
				if (filterAssoc["estado_ad"] != a.adhesion_estado ) {
                         return false;
                    }
				if (filterAssoc["estado_af"] == a.afiliacion_estado ) {
                         return false;
                    }
                    /*
				if (filterAssoc["estado_ad"] == '' && a.adhesion_estado =='') {
					return false;
                    } else 
                    if (
					filterAssoc["estado_ad"] == 1 &&
					a.ficha_firma == 0
				) {
					return false;
				}

				if (filterAssoc["filled"] == 0 && a.ficha > 0) {
					return false;
				} else if (filterAssoc["filled"] == 1 && a.ficha == 0) {
					return false;
				}

				if (
					filterAssoc["sended"] > 0 &&
					(a.usuario_id == this.usuario_logged ||
						a.usuario_id.length == 0)
				) {
					return false;
				} else if (
					filterAssoc["sended"] == 0 &&
					a.usuario_id != this.usuario_logged &&
					a.usuario_id.length > 0
				) {
					return false;
				}
*/
				return true;
			});

			if (this.dni.length > 0) {
				this.afiliados = this.afiliados.filter(
					a => a.dni.toString().indexOf(this.dni) > -1
				);
			}

			if (sort) {
				this.afiliados.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
			}
		},
		async closeAfiliado(reload) {
			this.afiliado = false;
			this.plmap = false;
			if (reload) {
				await this.reload();
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
				this.filtered(this.departamento);
			}
		},
		async open(reload) {
			this.afiliado = false;
			if (reload) {
				await this.reload();
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
				this.filtered(this.departamento);
			}
		},
		showimages(item) {
			var file1 =
				"https://plafiliaciones.s3.sa-east-1.amazonaws.com/images/" +
				item["file1"]["file"];
			var file2 =
				"https://plafiliaciones.s3.sa-east-1.amazonaws.com/images/" +
				item["file2"]["file"];
			this.item_pic_selected = {
				file1: file1,
				file2: file2,
				title:
					item.nombre +
					" " +
					item.apellido +
					"(" +
					item.movil +
					")"
			};
		}
	}
};
</script>
<style scoped>
.dw-checkbox.selected .dw-check {
	background: red;
}
.dw-check {
	top: 10%;
	left: 10%;
	width: 92%;
	height: 80%;
	position: absolute;
}
.dw-checkbox {
	position: relative;
	width: 20px;
	height: 20px;
	border: 1px solid white;
}

.change-button {
	z-index: 5 !important;
	right: 103px !important;
}
.page-list.selectedPage {
	color: white;
	text-decoration: none;
}
.page-count {
	font-size: 12px;
	margin-right: 10px;
	width: 100%;
	color: red;
	width: 100%;
	float: left;
	text-align: center;
}
.page-list {
	cursor: pointer;
	font-size: 14px;
	margin-right: 10px;
	color: aqua;
	width: 100%;
	text-decoration: underline;
	float: left;
	text-align: center;
}
.pageLabel {
	font-size: 14px;
	margin-right: 10px;
	color: white;
	float: left;
}
.page-items {
	float: left;
}
</style>