<template>
	<div class="text-center" v-if="dialog">
		<v-dialog persistent hide-overlay v-model="dialog" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">Ingreso</v-card-title>

				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field v-model="username" label="Nombre de usuario" required></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field v-model="password" label="Contraseña" type="password" required></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="ingresar">Ingresar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
var user = [];
var pass = [
	"123456",
	//"Cdxr09Od",
	"MVKFiaac",
	"BDGcCvT6",
	"06lPYWnh",
	"S7Hgnm8q",
	"6JszE6N8",
	"oZKEzX3h",
	"GmPMO71L",
	"OusPRNEj",
	"QTO4mfwh",
	"PCi7zhQX",
	"GvXWfggJ",
	"ZFo8EIEI",
	"YVsrs22P",
	"fzH78rTI",
	"duj0JCGu",
	"hy7Kjp41",
	"uA3vM8Qs",
	"YqpxvOW7",
	"e6cW2cQo",
	"BX7RFPXs",
	"wTbVUfhA",
	"X4JFyQU5",
	"DdOqBH7Y",
	"ss5OADtg",
	"uAoHJdzi",
	"vnUsavbn",
	"eFJdS6AC",
	"rfQav9fk",
	"XPTJb0on"
];
export default {
	data() {
		return {
			username: "",
			password: "",
			dialog: true
		};
	},
	async mounted() {
          await this.$store.commit('iface/setWait',{wait:true});
          if (this.$store.state.afiliaciones.userds!==false){
               this.$emit('login',this.username)
          }
          await this.$store.commit('iface/setWait',{wait:false});
          
	},
	methods: {
		async ingresar() {
               await this.$store.commit('iface/setWait',{wait:true});
               await this.$store.dispatch('afiliaciones/testUserRds',{username:this.username,password:this.password});
               if (this.$store.state.afiliaciones.userds!==false){
                    this.$emit('login',this.username)
               }
               await this.$store.commit('iface/setWait',{wait:false});
               
		}
	}
};
</script>