<template>
	<v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="slide-x-transition">
		<component
			v-bind:is="currentPost"
			
			:value="open"
			@add="addedPost"
			@close="close"
		></component>
	</v-dialog>
</template>

<script>
import postDatos from "./datos/post_datos.vue";
/*
import postOpinion from 'datos/post_datos.vue'
import postSugerencia from 'datos/post_datos.vue'
import postProyecto from 'datos/post_datos.vue'
import postRevision from 'datos/post_datos.vue'
import postOpcion from 'datos/post_datos.vue'
*/
//return ['Opinión', 'Datos', 'Sugerencia', 'Proyecto', 'Elección'];
import { mapGetters } from "vuex";

export default {
	components: {
		postDatos /*,postOpinion,postSugerencia,postProyecto,postRevision,postOpcion*/
     },
     model:{
          prop:'open',
          event:'send'
     },
     props: ["type",  "open"],
     name:'post',
	data() {
		return {
			dialog: false,
			post_dialog: false,
			currentPost: ""
		};
	},
	watch: {
		open: {
			handler(val) {
                    console.log('val',val);
                    if (val==false){
                         this.dialog = false;
                         this.$emit('close');
                         return;
                    }
                    var assocList = this.$store.getters['post/getPostTypes']
                    this.currentPost = assocList[val.type]['mod'];

                    console.log('val',val,this.currentPost);
                    this.dialog = val;
			},
			immediate: true
		},
		post_dialog: {
			handler(val) {
				this.dialog = val;
			},
			immediate: true
		}
	},
	computed: {
		...mapGetters("post", ["getTypes"])
	},
	mounted() {},
	methods: {
		addedPost(val) {
               this.$emit("add", val);
               this.dialog = false;
		},
		close(val) {
               this.dialog = false;
               console.log('emit close post');
			this.$emit("close", val);
		}
	}
};
</script>