<template v-cloack>
	<component
		@edit="editPost"
		@add="addNew"
		v-bind:post="post"
          @show="showPost"
		v-bind:is="assocPostType[post['type']]"
	></component>
</template>

<script>
import itemPostDatos from "./item_post_datos.vue";

export default {
	name: "item-post",
	props: ["post"],
	components: { itemPostDatos },
	data() {
		return {
			assocPostType: {
				Datos: "itemPostDatos"
			}
		};
     },
     mounted(){
          console.log('this.post',this.post);
     },
	methods: {
		showPost(data) {
               console.log('data',data);
               this.$emit('select',data.id);
               
          },
		editPost(data) {
			this.$emit("edit", data);
		},
		addNew(data) {
			this.$emit("add", data);
		}
	}
};
</script>