export default {
     data() {
          return {
               options_nav: {
                    duration: 600,
                    offset: 0,
                    easing: "easeInOutCubic"
               },
               sections: [
                    {
                         text: 'Visión',
                         link: '#vision',
                    },
                    {
                         text: 'Afiliate',
                         link: '#afiliaciones',
                    },
                    {
                         text: 'quienes somos',
                         link: '#nosotros',
                    },
                    {
                         text: 'compromisos',
                         link: '#compromisos',
                    },
                    {
                         text: 'política',
                         link: '#politica',
                    }
               ],
               dialog: false,
               selected: {},
               quienes: [],
               init: '',
               afiliaciones: {
                    title: '',
                    text: ''
               },
               articles: [],
               features: [
                    {
                         icon: 'mdi-account-group-outline',
                         title: 'Impuestos',
                         text: 'Impulsaremos y votaremos a favor de reducir y simplificar impuestos.',
                    },
                    {
                         icon: 'mdi-update',
                         title: 'Reducir Trámites y Desburocratizar',
                         text: 'Impulsaremos reducción de oficinas, controles, secretarías y trámites en cualquier nivel del estado',
                    },
                    {
                         icon: 'mdi-shield-outline',
                         title: 'Publicidad y presencia',
                         text: 'Informar y explicar nuestras acciones en cada sesión en la que participemos, como también a tener contacto directo con los ciudadanos.',
                    },
               ],
               stats: [
                    ['24k', 'Github Stars'],
                    ['330+', 'Releases'],
                    ['1m', 'Downloads/mo'],
                    ['5m', 'Total Downloads'],
               ],
          }
     },
     mounted() {
          this.quienes = this.$store.state.front.quienes;
          this.afiliaciones = {
               text: this.$store.state.front.afiliaciones.text,//.split("\n").join('<br/>'),
               title: this.$store.state.front.afiliaciones.title
          }
          this.init = this.$store.state.front.init.text;//.split("\n").join('<br/>');
          console.log('this.init', this.init);
          var articles = this.$store.state.front.articles.map(a => {
               return {
                    ...a,
          //          text: a.text.split("\n").join('<br/>')
               }
          })
          this.articles = articles.filter(a => {
               return typeof a.summary !== 'undefined'
          })

          this.minarticles = articles.filter(a => {
               return typeof a.summary == 'undefined'
          });
     },
     methods: {
          openArticle(article) {
               this.selected = article;
               this.dialog = true;

          },
          goAfiliaciones(){
               location.href="https://afiliate.partidolibertariomendoza.com.ar/";
          }
     }
}