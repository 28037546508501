<template>
	<div>
		<cform v-if="contacto" :contacto="contacto" @close="closeContacto" />
		<v-simple-table fixed-header class="fill-height" style="padding-bottom:95px;">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">
							<v-btn text @click="sortAction('apellido')">Nombre</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('departamento')">Departamento</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('detalle')">Detalle</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('fecha')">Fecha</v-btn>
						</th>
						<th class="text-left">Telefono</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,i) in contactos" :key="i">
						<td>
							<v-btn  text v-on:click="clickContacto(item)">{{ item.name }}</v-btn>
						</td>
						<td>{{ item.departamento }}</td>
						<td>{{ item.detail_res }}</td>
						<td>{{ item.fecha_ts }}</td>
                              <td>{{ item.phone }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
import cform from "./contact_form.vue";

export default {
	components: { cform },
	props: ["departamento"],
	data() {
		return {
			contacto: false,
			contactos: []
		};
	},
	watch: {
		departamento: {
			handler(val) {
				this.update(val);
			},
			immediate: true
		}
	},
	mounted() {
		//this.update();
	},
	methods: {
		clickContacto(item) {
			this.contacto = item;
		},
		closeContacto(value) {
               if (value){
                    this.update();     
               }
			this.contacto = false;
		},
		sortAction() {},
		async update(provincia) {
               this.$store.commit("iface/setWait", { wait: true });
			await this.$store.dispatch(
				"afiliaciones/fetchContactosRds",
				provincia
			);
			this.contactos = this.$store.state.afiliaciones.contactos.map(
				c => {
					var today = new Date(c.fecha*1000);
					var dd = today.toISOString().split('T')[0].split("-").reverse().join("-")
					var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
                         var yyyy = today.getFullYear();
                         
                         return {
                              ...c,
                              detail_res:c.detail.toString().substr(0,100)+"...",
                              fecha_ts:dd
                         }
				}
               );
               this.$store.commit("iface/setWait", { wait: false });

		}
	}
};
</script>