<template>
	<div class="text-center">
          <pic v-if="item_pic_selected" :item="item_pic_selected" @close="item_pic_selected=false" />
		<v-dialog persistent v-model="dialog" scrollable width="500" :fullscreen="isMobile">
			<v-card>
				<v-card-title class="headline blue darken-2">Datos: {{afiliado.nombre}} {{afiliado.apellido}}</v-card-title>

				<v-card-text style="height: 80hv;">
					<v-container v-if="typeof datos.nacimiento_fecha!==undefined">
						<v-simple-table fixed-header>
							<template v-slot:default>
								<tbody>
									<tr>
										<td>Nombre</td>
										<td>{{datos.nombre}} {{datos.apellido}}</td>
									</tr>
									<tr>
										<td>DNI</td>
										<td>{{format_dni}}</td>
									</tr>
									<tr>
										<td>Nombre padre</td>
										<td>{{datos.padre_nombre}} {{datos.padre_apellido}}</td>
									</tr>
                                             <tr>
										<td>Nombre madre</td>
										<td>{{datos.madre_nombre}} {{datos.madre_apellido}}</td>
									</tr>  
                                             <tr>
										<td @click="showimages(afiliado)" colspan="2">Ver Fotos</td>
									</tr>  									    
                                             <tr>
										<td>Domicilio</td>
										<td>{{datos.calle}} {{datos.numero}} {{datos.depto}} {{datos.piso}}</td>
									</tr>                                                                                    
                                             <tr>
										<td>Localidad</td>
										<td>{{datos.localidad}}</td>
									</tr>                                                                                    
									<tr>
										<td>Estado Civil</td>
										<td>{{datos.estado_civil}}</td>
									</tr>
									<tr>
										<td>Fecha de nacimiento</td>
										<td>{{nacimiento}}</td>
									</tr>
									<tr>
										<td>Lugar de nacimiento</td>
										<td>{{datos.nacimiento_lugar}}</td>
									</tr>
                                             <tr>
										<td>Ocupacion</td>
										<td>{{datos.profesion}}</td>
									</tr>  

									

								</tbody>
							</template>
						</v-simple-table>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="editar">Editar</v-btn>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import pic from "./pic.vue";
var dep = "Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle"
	.split(",")
	.sort();

export default {
     props: ["afiliado"],
     components:{pic},
	data() {
		return {
               datos: {},
               format_dni: '',
               nacimiento:'',
			items: [
				"no molestar",
				"colaborador activo",
				"solo avisar evento",
				"datos falsos/descartar"
			],
               dialog: true,
               item_pic_selected: false,
		};
     },
     computed:{
          isMobile(){
               return this.$vuetify.breakpoint.mobile || window.innerWidth < 700
          }
     },
     
	mounted() {

		this.datos = JSON.parse(this.afiliado.data);
          console.log("afiliado data", this.datos);
          console.log('dni',numberWithCommas(this.afiliado) );
          this.nacimiento = this.datos.nacimiento_fecha.split("-").reverse().join("-");
          this.format_dni = numberWithCommas(this.afiliado.dni);

		this.$store.commit("iface/setWait", { wait: false });
	},
	methods: {
		async editar() {
               var datos = JSON.parse(this.afiliado.data);
               datos.dni = this.afiliado.dni;
               datos.file1 = this.afiliado.file1;
               datos.file2 = this.afiliado.file2;
               datos.phone = this.afiliado.movil;
               datos.mail = this.afiliado.mail;
               console.log('datos.dni',datos.dni);
			this.$emit("editar", JSON.stringify(datos)  );
          },
          showimages(item) {
			var file1 =
				"https://plafiliaciones.s3.sa-east-1.amazonaws.com/images/" +
				item["file1"]["file"];
			var file2 =
				"https://plafiliaciones.s3.sa-east-1.amazonaws.com/images/" +
				item["file2"]["file"];
			this.item_pic_selected = {
				file1: file1,
				file2: file2,
				title:
					item.nombre +
					" " +
					item.apellido +
					"(" +
					item.movil +
					")"
			};
		},
		close() {
			this.$emit("close", false);
		}
	}
};

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>