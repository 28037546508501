<template>
	<v-row justify="center">
		<v-dialog v-model="show" max-width="400px">
			<v-card>
				<v-list-item v-for="(item,i) in getList" :key="i" @click="select(item)">
					<v-list-item-content>
						<v-list-item-title>{{item.Title}}</v-list-item-title>
						<p class="text-explain">{{item.Text}}</p>
					</v-list-item-content>
				</v-list-item>
			</v-card>
		</v-dialog>
	</v-row>
</template>
<script>

export default {
	props: ["list"],
	data() {
		return {
			show: false
		};
     },
     computed:{
          getList(){
               var assocList = this.$store.getters['post/getPostTypes']
               console.log(this.list);
               var ret = this.list.map(l=>{
                    return {
                         ...assocList[l],
                         code:l
                    }
               })
               console.log('this.list',this.list);
               console.log('ret',ret);
               return ret;
          }
     },
	watch: {
		list(val) {
			this.show = val.length > 0;
		}
	},
	methods: {
		select(item) {
               this.$emit('selected',item.code);
          }
	}
};
</script>
<style scoped>
.text-explain{
    color: #666;
    font-size: 14px;
    }
</style>