const fs = require('fs')
const data = require('./front.json');
console.log(typeof data)
console.log(data)

const state = {
     articles:data.articles,
     init:data.init,
     afiliaciones:data.afiliaciones,
     quienes:data.quienes
}
const mutations = {
     
}
const actions = {
}
const getters = {
     
}




export default {
     namespaced: true,
     state, getters, actions, mutations
}

