<template>
	<v-row justify="center">
		<v-dialog v-model="d" persistent max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">User Profile</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" sm="6" md="6">
								<v-text-field v-model="name" label="Denominacion" required></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="close()">Close</v-btn>
					<v-btn color="blue darken-1" text @click="save">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: ["open"],
	data() {
		return {
               d: false,
               name:''
		};
	},
	watch: {
		open: {
			handler(val) {
                    this.name=''
				this.d = val;
			},
			immediate: true
		}
	},
	methods: {
          close(){
               this.$emit('close');
               this.d = false;
          },
		save() {
			this.$emit("add", {
				_n: this.name,
				_t: this.name
			});
			this.d = false;
		}
	}
};
</script>