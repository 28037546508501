var enc = require('simple-encryptor');
var _KPA = '2vb17oDC7bWdvITERXqi2ynpcSEbnIg9fFI68Qe4';
var _TOKEN = '';

var _ENC = null;



const __VARS = [252, 441, 349, 119, 151, 269, 58, 399, 416, 329, 147, 465, 433, 222, 121, 84, 353, 438, 281, 407, 373, 120, 348, 71, 56, 362, 266, 183, 447, 292];
const __JT_BO = __VARS[14];
const __AR_XD = Math.ceil(__JT_BO / 5);





//import $ from 'jquery'
const URL_USER_RDS = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/rds/login';
const URL_USER_SAVE = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/rds/user';
const URL_SAVE_RDS = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/rds/afiliaciones';
const URL_FETCH_AFILIACIONES = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/afiliaciones/fetch';
const URL_FETCH_CONTACTOS = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/contactos/fetch';
const URL_SAVE_CONTACTO_RDS = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/contactos/update';
const URL_INSERT_CONTACTO_RDS = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/contactos';
const URL_FETCH_COLABORACION = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/db/f/e/t/c/h/c';
const URL_USER_FETCH = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/db/f/u';
const URL_CHANGE_ESTADO_FICHAS = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/afiliaciones/estado';

const URL_AFILIADO_SEND = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/send/af';


const URL_SAVE = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/afiliaciones';
const URL_SEND_CODE = 'https://hk5hd2detf.execute-api.us-east-1.amazonaws.com/dev/sendcode';
const URL_CODE_TEST = 'https://hk5hd2detf.execute-api.us-east-1.amazonaws.com/dev/confirmcode';



const URL_GET_MOVES = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/af/moves';
const URL_VIEW_DATA = 'https://qh496ehwof.execute-api.us-east-1.amazonaws.com/dev/view';






const state = {
     users: [],
     departamentos: [],
     colaboracion: [],
     contactos: [],
     afiliaciones: [],
     afiliacion: {},
     permisos: {
          pafiliados : 0,
          pcontactos : 0,
          pcolaboradores : 0,
          level : 0,
     },
     token: '',
     userds: false,
};


const mutations = {

     setUsers(state, users) {
          state.users = users;
     },
     setRdsDepartamentos(state, departamentos) {
          state.departamentos = departamentos;
     },
     setPermisos(state, permisos) {


          state.permisos = {
               pafiliados : permisos._p_afiliados,
               pcontactos : permisos._p_contactos,
               pcolaboradores : permisos._p_colaboradores,
               level : permisos._level,
               admin : permisos._admin,
          };
          console.log('setPermisos',state.permisos);
     },
     setUserRds(state, userds) {
          state.userds = userds;
     },
     setAfiliaciones(state, afiliaciones) {
          state.afiliaciones = afiliaciones;
     },
     setColaboracion(state, colaboracion) {
          state.colaboracion = colaboracion;
     },
     setContactos(state, contactos) {
          state.contactos = contactos;
     },

     setAfiliacion(state, afiliacion) {
          state.afiliacion = afiliacion;
     },
     saved() {

     },
     confirmCode() {
     },
     setToken(state, token) {
          state.token = token;
     },
     sendedCode() {

     },

}

const actions = {
     init: async (context) => {





          var username = localStorage.getItem('userName');
          var departamentos = localStorage.getItem('departamentos');
          var permisos = localStorage.getItem('permisos') || JSON.stringify({
               _p_afiliados: 0,
               _p_colaboradores: 0,
               _p_contactos: 0,
               _level: 0,
               _admin: 0,
          });

          var permisos = JSON.parse(permisos);
          if (username && (departamentos || permisos._level) ) {
               context.commit('setUserRds', username);
               context.commit('setRdsDepartamentos', departamentos.split(","));
               if (permisos) {
                    context.commit('setPermisos', permisos);
               }

          }


          return;

          var url = 'https://hk5hd2detf.execute-api.us-east-1.amazonaws.com/dev/register';

          var sendata = {
               "_a": "l1rc",
               "_77qid82": "kAR",
               "_9rrg": "QJFf0d"
          }


          var res = await sendInit(url, sendata);
          if (res.token == 'forbidden') {
               context.commit('setToken', res.token);
          }
          else {
               console.log('res', res)
               _TOKEN = res.token;
               var afiliado_ocupacion = res.key.substr(__JT_BO, __AR_XD)

               _ENC = enc(afiliado_ocupacion);

          }


          //var res = await sendEnc(url_test,sendata,afiliado_ocupacion);

     },
     sendcode: async (context, { phone, dni, mail }) => {
          var res = await sendEnc(URL_SEND_CODE, { phone, dni, mail });
          context.commit('sendedCode', res['result']);
     },
     saveAfiliacion: async (context, afiliacion) => {
          var res = await sendEnc(URL_SAVE + "/0", afiliacion);
          if (res['result']==0){
               return false;
          }
          else{
               context.commit('saved');
               return true;
          }
          
     },
     updateAfiliacionSaveRds: async (context, { id, fields }) => {
          await sendEnc(URL_SAVE_RDS, { id, fields, username: state.userds });
     },
     fetchAfiliacion: async (context, afiliacion) => {
          // await fetch(URL_SAVE, afiliacion);
     },
     fetchAfiliaciones: async (context,params) => {
          var result = await sendEnc(URL_FETCH_AFILIACIONES, params);
          context.commit('setAfiliaciones', result['result']);
     },
     testCode: async (context, { phone, code }) => {
          var res = await sendEnc(URL_CODE_TEST, { phone, code });
          context.commit('confirmCode', res['result']);
     },
     testUserRds: async (context, { username, password }) => {
          var res = await sendEnc(URL_USER_RDS, { username, password });
          if (res['result'] != 0) {
               context.commit('setUserRds', username);
               context.commit('setRdsDepartamentos', res['result'].toString().length > 3 ? res['result'].split(",") : []);
               context.commit('setPermisos', {
                    _p_afiliados : res['access']['_p_afiliados'],
                    _p_colaboradores : res['access']['_p_colaboradores'],
                    _p_contactos : res['access']['_p_contactos'],
                    _level : res['access']['_level'],
                    _admin : res['access']['_admin'],
                    
               });

               localStorage.setItem('userName', username);
               localStorage.setItem('departamentos', res['result'].toString().length > 3 ? res['result'].split(",") : []);
               localStorage.setItem('permisos', JSON.stringify(res['access']));
          }

     },
     changeEstadoFichas: async (context,send)=>{
          var res = await sendEnc(URL_CHANGE_ESTADO_FICHAS, send);
     },
     logout: async (context) => {
          localStorage.setItem('userName', '');
          localStorage.setItem('departamentos', '');
          localStorage.setItem('permisos', '');
     },
     fetchColaboracionRds: async (context) => {
          var result = await ajaxGet(URL_FETCH_COLABORACION);
          context.commit('setColaboracion', result);
     },
     fetchContactosRds: async (context, provincia) => {
          var result = await sendEnc(URL_FETCH_CONTACTOS, { provincia: provincia });
          context.commit('setContactos', result['result']);
     },
     updateContactoSaveRds: async (context, { id, fields }) => {
          await sendEnc(URL_SAVE_CONTACTO_RDS, { id, fields });

     },
     insertContactoSaveRds: async (context, fields) => {
          await sendEnc(URL_INSERT_CONTACTO_RDS, fields);

     },
     sendAfiliadoUser: async (context, fields) => {
          await sendEnc(URL_AFILIADO_SEND, fields);

     },
     fetchUsers: async (context, provincia) => {
          var result = await getEnc(URL_USER_FETCH);
          context.commit('setUsers',result['result'])
          return result['result'];
          
     },
     getMoves: async(context, id)=>{
          var result = await getEnc(URL_GET_MOVES+"?af="+id);
          return result['result'];
     },
     viewData: async(context, {t,td,usuario_id})=>{
          var result = await sendEnc(URL_VIEW_DATA,{t,td,usuario_id});
          return result['result'];
     },
     saveUser: async(context, opt)=>{
          var result = await sendEnc(URL_USER_SAVE,opt);
          return result['result'];
     }
}

const getters = {

}


export default {
     namespaced: true,
     state, getters, actions, mutations
}





function sendInit(url, data) {
     var senddata = JSON.stringify(data);
     /*return $.ajax({
          url: url,
          type: 'post',
          headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
               'x-api-key': _KPA
          },
          crossDomain: true,
          contentType: 'application/json; charset=UTF-8',
          dataType: "json",
          data: senddata
     });

     var senddata = _ENC.encrypt(JSON.stringify(data));*/
     console.log('senddata', senddata);
     return ajaxPost(url, senddata, {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-api-key': _KPA
     });
}

async function sendEnc(url, data) {
     var senddata = JSON.stringify(data);
     var res = await ajaxPost(url, senddata, {
          'Content-Type': 'application/x-www-form-urlencoded',
     });
     if (typeof res['result'] == 'string') {
          if (res.result.indexOf("socket hang up") > -1) {
               var res = await sendEnc(url, data);
               return res;
          }
     }
     return res;


}

async function getEnc(url, data) {
     var senddata = JSON.stringify(data);
     var res = await ajaxGet(url, senddata, {
          'Content-Type': 'application/x-www-form-urlencoded',
     });
     if (typeof res['result'] == 'string') {
          if (res.result.indexOf("socket hang up") > -1) {
               var res = await getEnc(url, data);
               return res;
          }
     }
     return res;


}
async function _sendEnc(url, data) {
     var senddata = _ENC.encrypt(JSON.stringify(data));
     console.log('url', url);
     console.log('senddata', senddata);
     var res = await ajaxPost(url, senddata, {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': _TOKEN
     });
     if (typeof res['result'] == 'string') {
          if (res.result.indexOf("socket hang up") > -1) {
               var res = await sendEnc(url, data);
               return res;
          }
     }
     return res;


}

function fetch(url, data, vars) {
     /*
          vars = vars || {};
          for (var i in vars) {
               url = url.replace(':' + i, vars[i])
          }
     
          console.log('url', url);
     
          return $.ajax({
               url: url,
               type: "get",
               data: data,
               crossDomain: true,
     
          });*/
}


function ajaxGet(url, data, header) {

     return new Promise(function (res, rej) {
          header = header || {};
          var request = new XMLHttpRequest();

          request.open('GET', url, true);



          for (var i in header) {
               request.setRequestHeader(i, header[i]);
          }

          request.onload = function () {
               if (this.status >= 200 && this.status < 400) {
                    // Success!
                    var resp = JSON.parse(this.response);
               } else {
                    // We reached our target server, but it returned an error
               }
               res(JSON.parse(this.response));
          };

          request.onerror = function (e) {
               // There was a connection error of some sort
               rej(e)
          };
          console.log('ajaxpost', data);
          request.send(data);
     })



}

function ajaxPost(url, data, header) {

     return new Promise(function (res, rej) {
          header = header || {};
          var request = new XMLHttpRequest();

          request.open('POST', url, true);



          for (var i in header) {
               request.setRequestHeader(i, header[i]);
          }

          request.onload = function () {
               if (this.status >= 200 && this.status < 400) {
                    // Success!
                    var resp = JSON.parse(this.response);
               } else {
                    // We reached our target server, but it returned an error
               }
               res(JSON.parse(this.response));
          };

          request.onerror = function (e) {
               // There was a connection error of some sort
               rej(e)
          };
          console.log('ajaxpost', data);
          request.send(data);
     })



}