

import cat from "./cat.vue"
import post from "./post/post.vue"
//import itemPost from "./items_post/item_post.vue"
//import itemPostDatos from "./items_post/item_post_datos.vue"
import itemPostSelect from "./items_post/item_post_select.vue"
import { mapGetters } from "vuex";
import wait from "./../wait.vue";


export default {
     components: { "cat-dialog": cat, "post-dialog": post, itemPostSelect, wait },
     props: {
          source: String,

     },
     computed: {
          getFilteredPostMain() {
               return this.$store.getters['post/getMain'].map(p => {
                    return {
                         ...p,
                         css_type: "type_" + makeSlug(p.type)
                    }
               });
          },
          getFilteredPostParents() {
               return this.$store.getters['post/getParents'].map(p => {
                    return {
                         ...p,
                         css_type: "type_" + makeSlug(p.type)
                    }
               });
          },
          getFilteredPost() {
               return this.$store.getters['post/getPost'].map(p => {
                    return {
                         ...p,
                         css_type: "type_" + makeSlug(p.type)
                    }
               });
          },
          ...mapGetters('post', ['getCategories'])
     },
     data: () => ({

          bucket: 'plm-afi',
          path: 'post/images',
          drawer: null,
          cats: [],
          cat_selected: {},
          cat_index_selected: {},
          cat_dialog: false,
          post_dialog: false,
          post_selected: false,
          listType: [],
          response: false
     }),
     created() {
         this.init();


     },
     beforeRouteUpdate(to, from, next) {
          
          next();
     },
     watch: {

          $route: {
               
               async handler(to, from) {
                    await this.$store.dispatch('post/init');
                    this.$store.commit('iface/setWait', { wait: true });

                    //  this.cat_selected = item;
                    if (typeof to['params']['category'] !== 'undefined') {
                         await this.$store.dispatch('post/fetchPostFilter', { categories: [to['params']['category']] });

                    }
                    if (typeof to['params']['post'] !== 'undefined') {
                         await this.$store.dispatch('post/fetchPostFilter', { parent: [to['params']['post']] });

                    }
                    this.$store.commit('iface/setWait', { wait: false });
               }, 
               immediate: true
               // react to route changes...
          }
     },
     methods: {
          async init(){
               await this.$store.dispatch('users/loadUserLogger');
               await this.$store.dispatch('post/init');
               await this.$store.dispatch('post/fetchCategorias');
          },
          selectPost(id) {

               this.$router.push("/post/" + id);
          },
          fetchCat(item, i) {
               this.$router.push("/categories/" + item._t);
          },
          redirectCats() {


          },


          addNew(list, post) {
               console.log(arguments);
               this.response = post;
               this.listType = list;
               console.log('recive', this.listType, post);

          },
          selectType(type) {
               this.listType = [];
               this.post_selected = { id: 0, type: type, response: this.response };
               console.log('this.post_selected', this.post_selected);
               this.response = false;
          },

          addCat(item, i) {
               this.cat_dialog = true;
               this.cat_selected = item;
               this.cat_index_selected = i;
               console.log("this.cat_selected", this.cat_selected);

          },

          addedCat(item) {
               if (typeof this.cats[this.cat_index_selected].c == 'undefined') {
                    this.cats[this.cat_index_selected].c = [];
               }

               this.cats[this.cat_index_selected].c.push(item);
               this.$set(this.cats, this.cats);

               this.cat_dialog = false;

               console.log('this.cats', this.cats);

          },
          addedPost() {
               console.log('addedpost', this.cat_selected);
               this.fetchCat(this.cat_selected);
               this.post_selected = false;
          },
          editPost(item) {
               console.log('editPost', item);
               this.post_selected = item;
               console.log("edit post dashboard");
          },
          addPost() {
               this.listType = ['Datos'];
               //this.post_selected = {id:''};
          },
          closed() {
               console.log("closed dashboard");
               this.post_selected = false;
          }

     }
}
function makeSlug(text) {
     var a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
     var b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
     var p = new RegExp(a.split('').join('|'), 'g');

     return text.toString().toLowerCase().replace(/\s+/g, '-')
          .replace(p, function (c) {
               return b.charAt(a.indexOf(c));
          })
          .replace(/&/g, '-y-')
          .replace(/[^\w\-]+/g, '')
          .replace(/\-\-+/g, '-')
          .replace(/^-+/, '')
          .replace(/-+$/, '');
}