<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">Pase de afiliación</v-card-title>

				<v-card-text >
					<v-container>
						<v-row v-if="!success">
							<v-col cols="12">
								<label>
									<v-autocomplete outlined dense :items="usuarios" v-model="user"/>
								</label>
							</v-col>
						</v-row>
						<v-row v-if="success">
							<v-col cols="12" >
                                        <v-alert type="success">Acción realizada con éxito   </v-alert>
							</v-col>
                                   <v-col cols="12">
                                        <v-simple-table>
                                             <template slot:default>
                                                  <tbody>
                                                       <tr>
                                                            <td width="50%">Usuario Origen</td>
                                                            <td>{{success.origen}}</td>
                                                       </tr>
                                                       <tr>
                                                            <td>Usuario Destino</td>
                                                            <td>{{success.destino}}</td>
                                                       </tr>
                                                       <tr>
                                                            <td>Afiliado</td>
                                                            <td>{{success.afiliado}}</td>
                                                       </tr>
                                                  </tbody>
                                             </template>
                                        </v-simple-table>
                                   </v-col>
                                   
						</v-row>
						<v-row v-if="!success"> 
							<v-col cols="12" v-if="user">
                                        <v-btn @click="enviarAfiliado">Enviar</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

export default {
	props: ["afiliado"],
	data() {
		return {
               success:false,
               usuarios:[],
               dialog:true,
			checkbox: false,
			user: "",
               nombre: '',
               
		};
	},
	async mounted() {
          
          
          this.$store.commit("iface/setWait", { wait: false });
       
          this.usuarios =  (await this.$store.dispatch('afiliaciones/fetchUsers')).map(x=>x['username']);
          this.user = localStorage.getItem('enviar') || '';


	},
	methods: {
		async enviarAfiliado() {
			this.$store.commit("iface/setWait", { wait: true });
               
               
               console.log(this.$store.state.afiliaciones.userds);
               var opt = {
					user_id_from: this.$store.state.afiliaciones.userds,
                         user_id_to: this.user,
                         afiliado_dni : this.afiliado.dni
               };
               this.success = {
                    origen:opt.user_id_from,
                    destino:opt.user_id_to,
                    afiliado:this.afiliado.nombre + " " + this.afiliado.nombre +" ("+this.afiliado.dni+")"
               }


               localStorage.setItem('enviar',this.user) ;
               
          
               console.log('opt',opt);
			await this.$store.dispatch(
				"afiliaciones/sendAfiliadoUser",
				opt
			);
			this.$store.commit("iface/setWait", { wait: false });
			//this.$emit("close", true);
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>