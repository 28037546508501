<template>
	<!--<v-img
		src="https://plm-afi.s3.amazonaws.com/app/back.jpg"
	>-->
	<v-app id="main" :style="{background: $vuetify.theme.themes[theme].background}">
          <v-app-bar app color="deep-purple accent-4" dense dark>
			<v-toolbar-title>Afiliación Partido Libertario Mendoza</v-toolbar-title>

		</v-app-bar>
          <v-main>
			<InitTemplate />
	     </v-main>
          
		<!--<InitTemplate />-->
	</v-app>
	<!--</v-img>-->
</template>

<script>
// @ is an alias to /src
import InitTemplate from "@/components/InitTemplate.vue";

export default {
	name: "Home",
	computed: {
		theme() {
			return this.$vuetify.theme.dark ? "dark" : "light";
		}
	},
	components: {
		InitTemplate
	}
};
</script>
