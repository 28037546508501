<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500" :fullscreen="isMobile">
			<v-card>
				<v-card-title class="headline grey lighten-2">{{colaborador.nombre}}</v-card-title>

				<v-card-text style="height: 80hv;">
					<v-container>
						<v-row>
							<v-col cols="12">
								Enviar Whatsapp:
								<a
									target="_blank"
									:href="'https://api.whatsapp.com/send?phone=549'+colaborador.data.users.movil"
								>{{colaborador.data.users.movil}}</a>
							</v-col>
							<v-col cols="12">
								Enviar Whatsapp:
								<a
									target="_blank"
									:href="colaborador.data.users.mail"
								>{{colaborador.data.users.mail}}</a>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<label>
									<b>{{colaborador.tipo}}</b>
								</label>
							</v-col>
						</v-row>

						<v-row v-if="colaborador.tipo=='activismo-digital'">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
										<tr>
											<td>FB</td>
											<td>{{colaborador.data.Facebook}}</td>
										</tr>
										<tr>
											<td>Instagram</td>
											<td>{{colaborador.data.Instagram}}</td>
										</tr>
										<tr>
											<td>Twitter</td>
											<td>{{colaborador.data.Twitter}}</td>
										</tr>
										<tr>
											<td>Colaboracion</td>
											<td><b>{{colaborador.data.actividades.join(",")}}</b></td>
										</tr>
										<tr>
											<td>Horas Semanales</td>
											<td><b>{{colaborador.data.horas}}</b></td>
										</tr>
										<tr>
											<td>otras</td>
											<td><b>{{colaborador.data.otras}}</b></td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-row>
						<v-row v-if="colaborador.tipo=='profesion-conocimiento'">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
                                                  <tr>
											<td>Ocupación</td>
											<td><b>{{colaborador.data.ocupacion}}</b></td>
										</tr>
										<tr>
											<td>Aporte</td>
											<td>{{colaborador.data.aporte}}</td>
										</tr>
										<tr>
											<td>Horas Semanales</td>
											<td><b>{{colaborador.data.horas}}</b></td>
										</tr>
										<tr>
											<td>Horas Semanales</td>
											<td><b>{{colaborador.data.horas}}</b></td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-row>
						<v-row v-if="colaborador.tipo=='activismo'">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
                                                  <tr>
											<td>Actividades</td>
											<td><b>{{colaborador.data.actividades.join(",")}}</b></td>
										</tr>
										<tr>
											<td>Ambito</td>
											<td>{{colaborador.data.ambito.join(",")}}</td>
										</tr>
										<tr>
											<td>Horas Semanales</td>
											<td><b>{{colaborador.data.horas}}</b></td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-row>
						<v-row v-if="colaborador.tipo=='fiscalizacion'">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
                                                  <tr>
											<td>escuelas</td>
											<td><b>{{colaborador.data.escuelas}}</b></td>
										</tr>
										<tr>
											<td>Ambito</td>
											<td>{{colaborador.data.ambito.join(",")}}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-row>
						<v-row v-if="colaborador.tipo=='media'">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
                                                  <tr>
											<td>link</td>
											<td><b>{{colaborador.data.link}}</b></td>
										</tr>
										<tr>
											<td>tipo</td>
											<td>{{colaborador.data.tipo.join(",")}}</td>
										</tr>
										<tr>
											<td>tags</td>
											<td>{{colaborador.data.tags}}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-row>
						<v-row v-if="colaborador.tipo=='propuestas'">
							<v-simple-table dense>
								<template v-slot:default>
									<tbody>
                                                  <tr>
											<td>resumen</td>
											<td><b>{{colaborador.data.resumen}}</b></td>
										</tr>
                                                  <tr>
											<td>resumen</td>
											<td><b>{{colaborador.data.propuesta}}</b></td>
										</tr>
                                                  <tr>
											<td>Propuesta</td>
											<td><b>{{colaborador.data.propuesta}}</b></td>
										</tr>
										<tr>
											<td>Ambito</td>
											<td>{{colaborador.data.ambito.join(",")}}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-row>

						<v-row>
							<v-col cols="12">
								<v-select dense label="seleccionar" v-model="colaboracion" outlined :items="items"></v-select>
							</v-col>
							<v-col cols="12">
								<v-textarea dense v-model="observaciones" outlined label="Observaciones"></v-textarea>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="guardar">Guardar</v-btn>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
var dep = "Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle"
	.split(",")
	.sort();

export default {
	props: ["colaborador"],
	data() {
		return {
			checkbox: false,
			colaboracion: "",
			departamentos: dep,
			departamento: dep,
			observaciones: "",
			items: [
				"no molestar",
				"colaborador activo",
				"solo avisar evento",
				"datos falsos/descartar"
			],
			dialog: true
		};
     },
     computed:{
          isMobile(){
               return this.$vuetify.breakpoint.mobile || window.innerWidth < 700
          }
     },
	mounted() {
		this.$store.commit("iface/setWait", { wait: false });

		this.colaboracion = this.colaborador.colaboracion;
		this.observaciones = this.colaborador.observaciones || "";
	},
	methods: {
		async guardar() {
			this.$store.commit("iface/setWait", { wait: true });
			var opt = {
				fields: {
					observaciones: this.observaciones,
					colaboracion: this.colaboracion
				},
				id: this.colaborador.id
			};
			await this.$store.dispatch(
				"afiliaciones/updateColaboracionSaveRds",
				opt
			);
			this.$store.commit("iface/setWait", { wait: false });
			this.$emit("close", true);
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>