<template>
	<div class="upload-full">
		<div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active_2">
			<h3>Drop files to upload</h3>
		</div>
		<div class="upload_f" v-show="!isOption">
			<v-row v-if="filesList.length == 0 && files.length > 0 && removedFilesCount==0 ">
				<v-col>
					<div class="text-center p-5">
						<h4>Obteniendo imágenes...</h4>
					</div>
				</v-col>
			</v-row>
			<v-row v-else-if="filesList.length == 0">
				<v-col>
					<div class="text-center p-5">
						<h4>
							Arrastrar imágenes para subir
							<br />o
						</h4>
						<label :for="name" class="btn btn-lg btn-primary">Seleccionar archivo</label>
					</div>
				</v-col>
			</v-row>
			<v-row v-if="filesList.length">
				<v-col cols="12">
					<v-card>
						<v-container fluid>
							<draggable class="row" v-model="filesList">
								<div v-for="(file, index) in filesList" :key="file.id" class="d-flex child-flex">
									<v-card flat tile class="d-flex">
										<v-btn v-on:click="remove(file,index)" absolute dark fab top right x-small color="pink">
											<v-icon>mdi-delete</v-icon>
										</v-btn>

										<v-img width="100px" :src="file.thumb" aspect-ratio="1" class="grey lighten-2">
											<template v-slot:placeholder>
												<v-row class="fill-height ma-0" align="center" justify="center">
													<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</v-card>
								</div>
							</draggable>
						</v-container>
					</v-card>
				</v-col>
			</v-row>

			<!--	<div class="footer-status float-right">
					Drop: {{$refs.upload ? $refs.upload.drop : false}},
					Active: {{$refs.upload ? $refs.upload.active : false}},
					Uploaded: {{$refs.upload ? $refs.upload.uploaded : true}},
					Drop active: {{$refs.upload ? $refs.upload.dropActive : false}}
			</div>-->

			<file-upload
				input-id="file_1"
				name="file_1"
				:custom-action="uploadImage"
				:extensions="extensions"
				:accept="accept"
				:multiple="multiple"
				:directory="directory"
				:size="size || 0"
				:thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
				:headers="headers"
				:data="data"
				:drop="drop"
				:drop-directory="dropDirectory"
				:add-index="addIndex"
				v-model="filesList"
				@input-filter="inputFilter"
				@input-file="inputFile"
				ref="upload"
			></file-upload>
		</div>

		<!--<div :class="{'modal-backdrop': true, 'fade': true, show: editFile.show}"></div>-->
	</div>
</template>


<script>
//import Cropper from "cropperjs";
//import ImageCompressor from "@xkeshi/image-compressor";
import FileUpload from "vue-upload-component";
import draggable from "vuedraggable";
const axios = require("axios");

const CONFIG = require("./config.json");

const URL_FILE_UPLOAD = CONFIG.url_upload_mime;

export default {
	props: ["files", "path", "prefix", "bucket", "commit", "min", "max"],
	components: {
		FileUpload,
		draggable
	},
	errorHandler(e) {
		console.log("e", e);
	},
	data() {
		return {
			loadFiles: [],
			fileLoaded: [],
			filesAdded: [],
			filesList: [],
			filesAssoc: {},
			removeFiles: [],
			removedFilesCount: 0,
			accept: "image/jpeg",
			extensions: "jpg",
			// extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
			// extensions: /\.(gif|jpe?g|png|webp)$/i,
			minSize: 1024,
			size: 1024 * 1024 * 10,
			multiple: true,
			directory: false,
			drop: true,
			dropDirectory: true,
			addIndex: false,
			thread: 3,
			name: "file",
			headers: {
				"Content-Type": "multipart/form-data"
			},
			data: {
				_csrf_token: "xxxxxx"
			},
			autoCompress: 1024 * 1024,
			uploadAuto: false,
			isOption: false,
			addData: {
				show: false,
				name: "",
				type: "",
				content: ""
			},
			editFile: {
				show: false,
				name: ""
			},
			uploaded: 0,
			file_uploaded_list: []
		};
	},

	watch: {
		commit: {
			handler(val) {
				if (val > 0) {
					console.log("initUpload");
					this.initUpload();
				}
			},
			immediate: true
		},
		files: {
			handler() {
				var files = [];
				try {
					files = this.files.slice(0);
				} catch (e) {}
				this.fileLoaded = files;

				this.filesList = [];
				this.filesAssoc = {};
				this.filesAdded = [];
				this.readFile(files);
			},
			immediate: true
		},
		"editFile.show"(newValue, oldValue) {
			// 关闭了 自动删除 error
			if (!newValue && oldValue) {
				this.$refs.upload.update(this.editFile.id, {
					error: this.editFile.error || ""
				});
			}
			if (newValue) {
				this.$nextTick(function() {
					if (!this.$refs.editImage) {
						return;
					}
					let cropper = new Cropper(this.$refs.editImage, {
						autoCrop: false
					});
					this.editFile = {
						...this.editFile,
						cropper
					};
				});
			}
		},
		"addData.show"(show) {
			if (show) {
				this.addData.name = "";
				this.addData.type = "";
				this.addData.content = "";
			}
		}
	},
	mounted() {},
	methods: {
		async initUpload(files) {
			
			if (this.filesList.length == 0) {
				this.$emit("uploaded", []);
				this.$emit("removed", this.removeFiles.slice(0));
				this.removeFiles = [];
			} else {
				this.file_uploaded_list = [];
				for (var i = 0; i < this.filesList.length; i++) {
					var file = this.filesList[i];
					if (typeof file.file.name == "undefined") {
						continue;
					}

					console.log("file.file", file.file.name);
					var ext = file.file.name.substr(
						file.file.name.lastIndexOf(".") + 1
					);
					var filename =
						this.prefix + "_" + file["id"] + "." + ext;
					this.filesAssoc[filename] = file.file.name;
					this.file_uploaded_list.push({
						name: filename,
						file: file.file,
						bucket: this.bucket,
						path: this.path
					});
				}

				var sendEmit = [];
				for (var nameKey in this.filesAssoc) {
					sendEmit.push({
						file: nameKey,
						name: this.filesAssoc[nameKey]
					});
				}

				if (this.file_uploaded_list.length > 0) {
					await this._uploadImages(this.file_uploaded_list);
				}

				console.log("sendEmit", sendEmit);
				this.$emit("removed", this.removeFiles.slice(0));
				this.$emit("uploaded", sendEmit);
				this.removeFiles = [];

				//this.$refs.upload.active = true;
			}
		},

		uploadImage() {},
		async _uploadImages(files) {
			if (files.length > 0) {
				var file = files[0];
				console.log("upload file", file);
				var contentArch = await handleFileSelect(file.file);

				try {
					var res = await axios.post(URL_FILE_UPLOAD, {
						bucket: file.bucket,
						name: file.name,
                              content: contentArch,
                              path:this.path
					});

					var uploadedFile = files.shift();
					console.log("uploaded success:", uploadedFile.name);
				} catch (e) {
					console.log("falló: " + file.name);
				}
				return this._uploadImages(files);
			}

			return true;
		},
		remove(file, i) {
			console.log("rremove index:", i, file);
			var removeFileName = false; //this.filesAssoc[file.file.name];
			console.log("rem 1");
			removeFileName = this.filesAssoc[file.file.name];
			console.log("rem 2");
			delete this.filesAssoc[file.file.name];
			console.log("rem 3");
			this.removedFilesCount++;
			console.log("rem 4");
			this.filesList.splice(i, 1);
			console.log("rem ");
			console.log("this.filesList", this.filesList);
			this.removeFiles.push(removeFileName);
			//this.$refs.upload.remove(file.id);

			//console.log("this.removeFiles", this.removeFiles);
		},
		inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				if (
					/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(
						newFile.name
					)
				) {
					return prevent();
				}
				// Filter php html js file
				// 过滤 php html js 文件
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent();
				}
				// Automatic compression
				// 自动压缩
				if (
					newFile.file &&
					newFile.type.substr(0, 6) === "image/" &&
					this.autoCompress > 0 &&
					this.autoCompress < newFile.size
				) {
					newFile.error = "compressing";
					const imageCompressor = new ImageCompressor(null, {
						convertSize: Infinity,
						maxWidth: 512,
						maxHeight: 512
					});
					imageCompressor
						.compress(newFile.file)
						.then(file => {
							this.$refs.upload.update(newFile, {
								error: "",
								file,
								size: file.size,
								type: file.type
							});
						})
						.catch(err => {
							this.$refs.upload.update(newFile, {
								error: err.message || "compress"
							});
						});
				}
			}
			if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
				// Create a blob field
				// 创建 blob 字段
				newFile.blob = "";
				let URL = window.URL || window.webkitURL;
				if (URL && URL.createObjectURL) {
					newFile.blob = URL.createObjectURL(newFile.file);
				}
				// Thumbnails
				// 缩略图
				newFile.thumb = "";
				if (
					newFile.blob &&
					newFile.type.substr(0, 6) === "image/"
				) {
					newFile.thumb = newFile.blob;
				}
			}
		},
		// add, update, remove File Event
		inputFile(newFile, oldFile) {
			if (newFile && oldFile) {
				// update
				if (newFile.active && !oldFile.active) {
					// beforeSend
					// min size
					if (
						newFile.size >= 0 &&
						this.minSize > 0 &&
						newFile.size < this.minSize
					) {
						this.$refs.upload.update(newFile, {
							error: "size"
						});
					}
				}
				if (newFile.progress !== oldFile.progress) {
					// progress
				}
				if (newFile.error && !oldFile.error) {
					// error
				}
				if (newFile.success && !oldFile.success) {
					// success
				}
			}
			if (!newFile && oldFile) {
				// remove
				if (oldFile.success && oldFile.response.id) {
					// $.ajax({
					//   type: 'DELETE',
					//   url: '/upload/delete?id=' + oldFile.response.id,
					// })
				}
			}
			// Automatically activate upload
			if (
				Boolean(newFile) !== Boolean(oldFile) ||
				oldFile.error !== newFile.error
			) {
				if (this.uploadAuto && !this.$refs.upload.active) {
					this.$refs.upload.active = true;
				}
			}
		},
		alert(message) {
			alert(message);
		},
		onEditFileShow(file) {
			this.editFile = { ...file, show: true };
			this.$refs.upload.update(file, { error: "edit" });
		},
		onEditorFile() {
			if (!this.$refs.upload.features.html5) {
				this.alert("Your browser does not support");
				this.editFile.show = false;
				return;
			}
			let data = {
				name: this.editFile.name
			};
			if (this.editFile.cropper) {
				let binStr = atob(
					this.editFile.cropper
						.getCroppedCanvas()
						.toDataURL(this.editFile.type)
						.split(",")[1]
				);
				let arr = new Uint8Array(binStr.length);
				for (let i = 0; i < binStr.length; i++) {
					arr[i] = binStr.charCodeAt(i);
				}
				data.file = new File([arr], data.name, {
					type: this.editFile.type
				});
				data.size = data.file.size;
			}
			this.$refs.upload.update(this.editFile.id, data);
			this.editFile.error = "";
			this.editFile.show = false;
		},
		// add folader
		onAddFolader() {
			if (!this.$refs.upload.features.directory) {
				this.alert("Your browser does not support");
				return;
			}
			let input = this.$refs.upload.$el.querySelector("input");
			input.directory = true;
			input.webkitdirectory = true;
			this.directory = true;
			input.onclick = null;
			input.click();
			input.onclick = e => {
				this.directory = false;
				input.directory = false;
				input.webkitdirectory = false;
			};
		},
		async readFile(files) {
			for (var i = 0; i < files.length; i++) {
				var path =
					"https://" +
					this.bucket +
					".s3.amazonaws.com/" +
					this.path +
					"/" +
					files[i]["file"];

				this.filesAssoc[files[i]["file"]] = files[i]["name"];
				//var file = await getFileObject(path, files[i]["file"]);
				files[i]["thumb"] = path;
				files[i]["active"] = false;
				files[i]["error"] = false;
				files[i]["success"] = false;
				console.log("files", files[i]["thumb"]);
				//this.loadFiles.push(path);
				this.filesList.push(files[i]);
			}
			console.log("this.loadFiles:::", this.filesList);

			this.$emit("readed");
		},
		onAddData() {
			this.addData.show = false;
			if (!this.$refs.upload.features.html5) {
				this.alert("Your browser does not support");
				return;
			}
			let file = new window.File(
				[this.addData.content],
				this.addData.name,
				{
					type: this.addData.type
				}
			);
			alert("xx");
			this.$refs.upload.add(file);
		}
	}
};
var getFileBlob = async function(url, cb) {
	/*	console.log("url", url);
	let blob = await fetch(url, {
		method: "GET",
		mode: "no-cors"
     }).then(r => {
               console.log('r',r);
          return r.blob()
     });
	console.log("blobk", blob);
	cb(blob);*/
	var xhr = new XMLHttpRequest();
	xhr.open("GET", url);
	xhr.responseType = "blob";
	xhr.setRequestHeader("Access-Control-Allow-Origin", "http://*");
	xhr.setRequestHeader("Access-Control-Allow-Origin", "https://*");
	xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");
	xhr.setRequestHeader("Access-Control-Allow-Origin", "*");

	xhr.addEventListener("load", function() {
		cb(xhr.response);
	});
	xhr.send();
};

var blobToFile = function(blob, name) {
	blob.lastModifiedDate = new Date();
	blob.name = name;
	return blob;
};

var getFileObject = function(filePathOrUrl, filename) {
	return new Promise(function(response, reject) {
		getFileBlob(filePathOrUrl, function(blob) {
			response(blobToFile(blob, filename));
		});
	});
};

function readImage(file) {
	console.log("file", file);
	// Check if the file is an image.
	if (file.type && file.type.indexOf("image") === -1) {
		console.log("File is not an image.", file.type, file);
		return;
	}

	const reader = new FileReader();
	reader.addEventListener("load", event => {
		img.src = file;
	});
	reader.readAsDataURL(file);
}

function handleFileSelect(f) {
	var reader = new FileReader();
	// Closure to capture the file information.

	return new Promise(function(resolve, reject) {
		reader.onload = (function(theFile) {
			return function(e) {
				var binaryData = e.target.result;
				//Converting Binary Data to base 64
				var base64String = window.btoa(binaryData);
				//showing file converted to base64
				resolve(base64String);
			};
		})(f);
		// Read in the image file as a data URL.
		reader.readAsBinaryString(f);
	});
}
</script>



<style scoped>
.upload-full .btn-group .dropdown-menu {
	display: block;
	visibility: hidden;
	transition: all 0.2s;
}
.upload-full .btn-group:hover > .dropdown-menu {
	visibility: visible;
}
.upload-full label.dropdown-item {
	margin-bottom: 0;
}
.upload-full .btn-group .dropdown-toggle {
	margin-right: 0.6rem;
}
.upload-full .filename {
	margin-bottom: 0.3rem;
}
.upload-full .btn-is-option {
	margin-top: 0.25rem;
}
.upload-full .example-foorer {
	padding: 0.5rem 0;
	margin-left: 25px;
	border-top: 1px solid #e9ecef;
	border-bottom: 1px solid #e9ecef;
}
.upload-full .edit-image img {
	max-width: 100%;
}
.upload-full .edit-image-tool {
	margin-top: 0.6rem;
}
.upload-full .edit-image-tool .btn-group {
	margin-right: 0.6rem;
}
.upload-full .footer-status {
	padding-top: 0.4rem;
}
.upload-full .drop-active_2 {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;
	z-index: 9999;
	opacity: 0.6;
	text-align: center;
	background: #000;
}
.upload-full .drop-active_2 h3 {
	margin: -0.5em 0 0;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 40px;
	color: #fff;
	padding: 0;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}
table {
	border-collapse: collapse;
}
.table td {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #e9ecef;
}
</style>