//import wait from "./../wait2.vue";
import login from "./login.vue";
import plmap from "./map.vue";
import usuarios from "./usuarios.vue";
import pldismap from "./mapdis.vue";
import contactos from "./contactos.vue";
import pllist from "./lista.vue";
import pllistaf from "./lista_af.vue";
import colaboracion from "./colaboracion.vue";
import user from "./user.vue";


import contactosAdd from "./contact_form_add.vue";


import wait2 from "./../wait2.vue";

export default {
     components: { usuarios, contactos, login, wait2, plmap, pldismap, contactosAdd,  colaboracion,  pllist,pllistaf,user },
     computed: {
          getDepartamentos() {
               return [];
          },

     },
     data() {
          return {
               dialog: '',
               prev_modulo: 'list',
               modulo: 'list',
               dnisearch: '',
               pldistmap: false,
               plmap: false,
               logged: false,
               drawer: true,
               group: true,
               departamentos: [],
               level: 0,
               admin: 0,
               departamento: false,
               item_pic_selected: false,
               sort: false,
               pcontactos: 0,
               pcolaboradores: 0,
               pafiliados: 0,

               b_assigned_items: false,
               usuario_logged: '',

          }
     },
     watch: {
          departamento(val) {
               this.drawer = false;
               this.openList(val);
          }
     },
     async mounted() {

          await this.$store.dispatch('afiliaciones/init');

     },
     methods: {
          viewJusticia(){
               this.b_assigned_items = new Date().getTime() * -1;
               console.log('this.b_assigned_items',this.b_assigned_items);
               this.departamento = '';
               this.modulo = '';
               var _this = this;
               this.$nextTick(function(){
                    _this.modulo = 'list_af';
                    _this.pldistmap = false;
               })
          },
          sendEvent(eventsend,param){
               switch (eventsend) {
                    case 'addNew':
                         this.addNew();
               }
          },
          openUsuarios() {
               this.modulo = 'usuarios';
               this.pldistmap = false;
               this.dialog = false;
          },
          openStat() {

          },
          async exit() {
               await this.$store.dispatch('afiliaciones/logout');
               location.reload();
          },
          
          addNew() {
               switch (this.modulo) {
                    case 'list':
                         this.$refs.plista.openAfiliadoAdd();
                         break;
                    case 'contactos':
                         this.openContactoAdd();
                         break;
                    case 'colaboracion':
                         this.openColaboracionAdd();
                         break;
               }
          },
          openColaboracionAdd() {
               this.prev_modulo = this.modulo;
               this.dialog = 'contacto';
               this.pldistmap = false;
          },
          openContactoAdd() {
               this.prev_modulo = this.modulo;
               this.dialog = 'contacto';
               this.pldistmap = false;
          },
          
          assignedItems() {
               this.b_assigned_items = new Date().getTime();
               console.log('this.b_assigned_items',this.b_assigned_items);
               this.departamento = '';
               this.modulo = '';
               var _this = this;
               this.$nextTick(function(){
                    _this.modulo = 'list';
                    _this.pldistmap = false;
               })
               
          },
          lastAdded() {
               this.b_assigned_items = new Date().getTime() * -1;
               console.log('this.b_assigned_items',this.b_assigned_items);
               this.departamento = '';
               this.modulo = '';
               var _this = this;
               this.$nextTick(function(){
                    _this.modulo = 'list';
                    _this.pldistmap = false;
               })
               
          },
          openList(departamento) {
               if (departamento==''){
                    return;
               }
               var _this = this;
               console.log('openList',departamento);
               this.b_assigned_items = false;
               this.departamento = departamento;
               this.$nextTick(function(){
                    _this.modulo = 'list';
                    _this.pldistmap = false;
               })
          },
          openContactos() {
               this.modulo = 'contactos';
               this.pldistmap = false;
          },
          openColaboracion() {
               this.modulo = 'colaboracion';
               this.pldistmap = false;
          },
          openMap() {
               this.modulo = 'map';
               this.pldistmap = true;
          },
          closeMap() {
               this.pldistmap = false;
          },
         
         
          async init() {
               this.logged = true;

               var permisos = this.$store.state.afiliaciones.permisos;
               this.usuario_logged = this.$store.state.afiliaciones.userds;
               this.level = permisos.level;
               this.admin = permisos.admin;


               this.pafiliados = permisos.pafiliados;
               this.pcolaboradores = permisos.pcolaboradores;
               this.pcontactos = permisos.pcontactos;

               this.departamentos = this.$store.state.afiliaciones.departamentos.sort().map(x=>x.trim());
               console.log("deptos",this.departamentos);
               this.departamento = this.departamentos[0];
          },
          editUser(){
               this.dialog = 'user';
          }
          
          
         
     }

}