<template>
	<v-app class="transparent">
		<v-content>
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4">
						<v-card class="elevation-12">
							<v-toolbar color="primary" dark flat>
								<v-toolbar-title>Enlace para referidos</v-toolbar-title>
								<v-spacer></v-spacer>
							</v-toolbar>
							<v-card-text>
								<v-alert dense outlined type="info" v-if="step==0">
									Queremos reconocer a quienes más luchan por las ideas de la libertad!
									<br />
									<br />Escribí tu número de teléfono para generar el enlace de afiliaciones que te identifica y que podrás compartir con tus amigos o familiares.
								</v-alert>
								<v-alert
									dense
									outlined
									type="success"
									v-if="step==1"
								>¡Listo! comparte este enlace y podremos saber a cuantos afiliaste y reconocerte tu esfuerzo.</v-alert>
								<v-form ref="form_1" v-model="valid_movil" :lazy-validation="false">
									<v-text-field
										label="Movil"
										name="movil"
										v-model="movil"
										:rules="[x=>x.length==10||'Numero móvil válido']"
										prepend-icon="mdi-account"
										type="number"
									></v-text-field>
									<v-text-field
										ref="textToCopy"
										id="textToCopy"
										label="Link"
										name="link"
										color="blue darken-2"
										readonly
										v-model="link"
										:hint="hint"
										prepend-icon="mdi-link"
										@click="select"
									></v-text-field>
								</v-form>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="primary" v-if="step==0" :disabled="!valid_movil" @click="next">Siguiente</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-content>
	</v-app>
</template>

<script>
export default {
	data() {
		return {
			link: "",
			movil: "",
			step: 0,
			valid_movil: false,
			hint: ""
		};
	},
	methods: {
		next() {
			this.link =
				"https://afiliate.partidolibertariomendoza.com/#/a/" +
				ntol(this.movil);
			this.hint = "";
			var _this = this;
			setTimeout(function() {
				let input = document.getElementById("textToCopy");
				console.log("input", input);
				input.focus();
				input.select();

				document.execCommand("copy");
				_this.hint = "EL ENLACE HA SIDO COPIADO";
			}, 500);
			this.step++;
		},
		select() {
			this.link =
				"https://afiliate.partidolibertariomendoza.com/#/a/" +
				ntol(this.movil);

			var _this = this;
			_this.hint = "";

			setTimeout(function() {
				let input = document.getElementById("textToCopy");
				console.log("input", input);
				input.focus();
				input.select();

				document.execCommand("copy");
				_this.hint = "EL ENLACE HA SIDO COPIADO";
			}, 500);
		}
	}
};
var alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
var base = alphabet.length;
function ntol(n) {
	var digits = [];

	do {
		var v = n % base;
		digits.push(v);
		n = Math.floor(n / base);
	} while (n-- > 0);

	var chars = [];
	while (digits.length) {
		chars.push(alphabet[digits.pop()]);
	}

	return chars.join("");
}
</script>
<style >
</style>


