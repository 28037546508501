<template>
	<div class="own-overlay" v-if="overlay">
		<div class="text-center">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</div>
	</div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
	data: () => ({
		overlay: false,
		text: "Procesando..."
	}),
	computed: {
		...mapGetters("iface", ["getWait"])
	},
	watch: {
		getWait(val) {
			this.overlay = val.wait;
			this.text = val.text;
		}
	},
	mounted() {}
};
</script>
<style scoped>
.own-overlay {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	background: rgba(255, 255, 255, 0.6);
	z-index: 100000;
}

.own-overlay .v-progress-circular.v-progress-circular--indeterminate {
	top: 47%;
}
</style>
