<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500" 
          :fullscreen="isMobile">
			<v-card>
				<v-card-title class="headline grey lighten-2">{{afiliado.nombre}} {{afiliado.apellido}}</v-card-title>

				<v-card-text style="height: 80hv;">
                         
					<v-container>
                           
						<v-row>
							<v-col cols="12">
								Enviar Whatsapp:
								<a
									target="_blank"
									:href="'https://api.whatsapp.com/send?phone=549'+afiliado.movil"
								>{{afiliado.movil}}</a>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-select label="seleccionar" v-model="colaboracion" outlined :items="items"></v-select>
								<v-checkbox dense :disabled="disabled_ficha" v-model="checkbox" label="Afiliado con ficha"></v-checkbox>
								<v-checkbox dense :disabled="disabled_ficha" v-model="checkbox_firma" label="Afiliado con firmas"></v-checkbox>
							</v-col>
							<v-col cols="12">
								<v-textarea v-model="observaciones" outlined label="Observaciones"></v-textarea>
							</v-col>
							<v-col cols="12">
								<v-select
									dense
									outlined
									:items="departamentos"
									label="Cambiar de departamento"
									v-model="departamento"
								></v-select>
							</v-col>
						</v-row>

                                 <v-simple-table dense v-if="moves.length">
                                   <template slot:default>
                                          <caption>Movimientos de las fichas</caption>

                                        <thead>
                                             <tr>
                                                  <th>Fecha</th>
                                                  <th>Desde</th>
                                                  <th>Hasta</th>
                                             </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(move,i) in moves" :key="i">
                                             <td>{{move.date}}</td>
                                             <td>{{move.from}}</td>
                                             <td>{{move.to}}</td>
                                                  
                                        </tr>
                                        </tbody>
                                   </template>
                              </v-simple-table>

					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="guardar">Guardar</v-btn>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
var dep = 'Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle'.split(",").sort();
var dayjs = require('dayjs')

export default {
	props: ["afiliado"],
	data() {
		return {
			checkbox: false,
			checkbox_firma: false,
               disabled_ficha: false,
               init_signed : false,

			colaboracion: "",
			departamentos: dep,
			departamento: dep,
			observaciones: "",
			moves: [],
			items: [
				"no molestar",
				"colaborador activo",
				"solo avisar evento",
				"datos falsos/descartar"
			],
			dialog: true
		};
     },
     computed:{
          isMobile(){
               return this.$vuetify.breakpoint.mobile || window.innerWidth < 700
          }
     },
     watch:{
          colaboracion(val){
               if(val=='datos falsos/descartar'){
                    this.checkbox = false;
                    this.checkbox_firma = false;
               }
               this.testFichaDisable();

          },
          checkbox(val){
               if (!val){
                    this.checkbox_firma = false;
               }
          },
          checkbox_firma(val){
               if (val){
                    this.checkbox = true;
               }
          }
     },
	mounted() {
		this.$store.commit("iface/setWait", { wait: false });

          this.$store.dispatch('afiliaciones/viewData',{t:'afiliados',td:this.afiliado.dni,usuario_id:this.$store.state.afiliaciones.userds});
		this.checkbox = this.afiliado.ficha == 1;
          this.checkbox_firma = this.afiliado.ficha_firma > 0;
          


          if (this.checkbox_firma){
               this.init_signed = true;
          }

		this.colaboracion = this.afiliado.colaboracion;
		this.observaciones = this.afiliado.observaciones || "";
          this.departamento = this.afiliado.departamento_vive || "";
          
          this.getMoves();
	},
	methods: {
          async getMoves(){
               this.moves = (await this.$store.dispatch('afiliaciones/getMoves',this.afiliado.dni)).map(r=>{
                    return {
                         ...r,
                         date : dayjs(r.date*1000).format("DD/MM/YYYY HH:mm")
                    }
               });

          },
          testFichaDisable(){
                    if (this.init_signed){
                         this.disabled_ficha = true;
                         return;
                    }
                    if (this.colaboracion=='datos falsos/descartar'){
                         this.disabled_ficha = true;
                         return;
                    }
                    this.disabled_ficha = false;
          },
		async guardar() {
			this.$store.commit("iface/setWait", { wait: true });
			var opt = {
				fields: {
					observaciones: this.observaciones,
					colaboracion: this.colaboracion,
					departamento_vive: this.departamento,
					ficha: this.checkbox
				},
				id: this.afiliado.id
               };
               

               if (!this.init_signed){
                    opt['fields']['ficha_firma'] = this.checkbox_firma ? (new Date().getTime()) / 1000 : 0;
                    opt['fields']['ficha_firma_usuario_id'] = this.checkbox_firma ?  this.$store.state.afiliaciones.userds : '';
               }
			await this.$store.dispatch(
				"afiliaciones/updateAfiliacionSaveRds",
				opt
			);
			this.$store.commit("iface/setWait", { wait: false });
			this.$emit("close", true);
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>