<template>
	<div class="example-full">
		<div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
			<h3>Suelta foto aqui</h3>
		</div>
		<div class="upload" v-show="!isOption">
			<div class="table-responsive">
				<table class="table">
					<tbody>
						<tr v-if="filesList.length == 0 && files.length > 0 && removedFilesCount==0 ">
							<td>
								<div class="text-center">
									<h4>Obteniendo imágenes...</h4>
								</div>
							</td>
						</tr>
						<tr v-else-if="filesList.length == 0">
							<td v-on:click="uploadFoto">
								<slot name="upload"></slot>
							</td>
						</tr>
						<tr v-if="filesList.length">
							<td v-on:click="uploadFoto">
								<v-row>
									<v-col cols="12">
										<v-card>
											<v-container fluid>
												<draggable class="row" v-model="filesList">
													<v-col
														v-for="(file, index) in filesList"
														:key="file.id"
														class="d-flex child-flex"
														cols="12"
													>
														<v-card flat tile class="d-flex">
															<v-btn
																v-on:click="remove(file,index)"
																absolute
																dark
																fab
																top
																right
																small
																color="pink"
															>
																<v-icon>mdi-delete</v-icon>
															</v-btn>

															<v-img :src="file.thumb" aspect-ratio="1.66" class="grey lighten-2">
																<template v-slot:placeholder>
																	<v-row class="fill-height ma-0" align="center" justify="center">
																		<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
																	</v-row>
																</template>
															</v-img>
														</v-card>
													</v-col>
												</draggable>
											</v-container>
										</v-card>
									</v-col>
								</v-row>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div>
				<file-upload
					:custom-action="uploadImage"
					:extensions="extensions"
					:accept="accept"
					:multiple="false"
					:input-id="id"
					:directory="directory"
					:size="1"
					:thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
					:headers="headers"
					:data="data"
					:drop="drop"
					:drop-directory="dropDirectory"
					:add-index="addIndex"
					v-model="filesList"
					@input-filter="inputFilter"
					@input-file="inputFile"
					ref="upload"
				></file-upload>
			</div>
		</div>
	</div>
</template>

<script>
//import Cropper from "cropperjs";
//import ImageCompressor from "@xkeshi/image-compressor";
import Compressor from 'compressorjs';
import FileUpload from "vue-upload-component";
import draggable from "vuedraggable";
const axios = require("axios");
const CONFIG = require("./../config.json");

const URL_FILE_UPLOAD = CONFIG.url_upload_mime;
export default {
	props: ["id", "files", "path", "prefix", "bucket", "commit", "min", "max"],
	components: {
		FileUpload,
		draggable
	},
	data() {
		return {
			fileLoaded: [],
			filesAdded: [],
			filesList: [],
			filesAssoc: {},
			removeFiles: [],
			removedFilesCount: 0,
			accept: "image/*",
			//extensions: "jpg",
			extensions: ["gif", "jpg", "jpeg", "png", "webp"],
			// extensions: /\.(gif|jpe?g|png|webp)$/i,
			minSize: 1024,
			size: 1024 * 1024 * 10,
			multiple: true,
			directory: false,
			drop: true,
			dropDirectory: true,
			addIndex: false,
			thread: 3,
			name: this.id,
			headers: {
				"Content-Type": "multipart/form-data"
			},
			data: {
				_csrf_token: "xxxxxx"
			},
			autoCompress: 1024 * 1024,
			uploadAuto: false,
			isOption: false,
			addData: {
				show: false,
				name: "",
				type: "",
				content: ""
			},
			editFile: {
				show: false,
				name: ""
			},
			uploaded: 0,
			file_uploaded_list: []
		};
	},

	watch: {
		commit: {
			handler(val) {
				if (val > 0) {
					console.log("initUpload");
					this.initUpload();
				}
			},
			immediate: true
		},
		files: {
			handler() {
				console.log("this.files", this.files);
				var files = [];
				try {
					files = this.files.slice(0);
				} catch (e) {
					console.log(e.stack);
				}
				this.fileLoaded = files;

				this.filesList = [];
				this.filesAssoc = {};
				this.filesAdded = [];
				this.readFile(files);
			},
			immediate: true
		}
	},
	mounted() {},
	methods: {
		async uploadFoto() {
			this.$refs.upload.$el.querySelector("#" + this.id).click();
		},
		async initUpload() {
			if (this.filesList.length == 0) {
				console.log("filelist 0");
				this.$emit("uploaded", []);
				this.$emit("removed", this.removeFiles.slice(0));
				this.removeFiles = [];
			} else {
				console.log("active upload", this.filesList);
				this.uploadImage(this.filesList[0]);
				//this.$refs.upload.active = true;
			}
		},
		async readFile(files) {
			for (var i = 0; i < files.length; i++) {
				var path =
					"https://" +
					this.bucket +
					".s3.amazonaws.com/" +
					this.path +
					"/" +
					files[i]["file"];

				this.filesAssoc[files[i]["file"]] = files[i]["name"];
				var file = await getFileObject(path, files[i]["file"]);
				this.$refs.upload.add(file);
			}
			this.$emit("readed");
		},
		async uploadImage(file) {
			console.log("uploadImages--------------", file);
			//si es el primero de la lista
			if (this.uploaded == 0) {
				this.file_uploaded_list = [];
			}
			try {
				var bfind = false;
				for (var i = 0; i < this.fileLoaded.length; i++) {
					if (this.fileLoaded[i]["file"] == file.file.name) {
						bfind = true;
						break;
					}
				}
				if (!bfind) {
					var filename = this.prefix + "_" + file["id"] + ".jpg";
					this.filesAssoc[filename] = file.file.name;
					this.file_uploaded_list.push({
						name: filename,
						file: file.file,
						bucket: this.bucket,
						path: "files"
					});
				}

				this.uploaded++;

				//si es el último
				console.log(this.uploaded + "/" + this.filesList.length);
				if (this.filesList.length == this.uploaded) {
					this.uploaded = 0;
					var sendEmit = [];
					for (var nameKey in this.filesAssoc) {
						console.log("sendemit", nameKey, this.filesAssoc);
						if (nameKey == "undefined") {
							continue;
						}
						sendEmit.push({
							file: nameKey,
							name: this.filesAssoc[nameKey]
						});
					}

					if (this.file_uploaded_list.length > 0) {
						await this._uploadImages(this.file_uploaded_list);
					}

					this.$emit("removed", this.removeFiles.slice(0));
					this.$emit("uploaded", sendEmit);
					this.removeFiles = [];
				}
			} catch (e) {
				console.log("e", e.stack);
			}

			return true;
		},
		async _uploadImages(files) {
			if (files.length > 0) {
				var file = files[0];
                    file.file = await compressImage(file.file)
				console.log("file", file);
				var contentArch = await handleFileSelect(file.file);

				try {
					await axios.post(URL_FILE_UPLOAD, {
						bucket: file.bucket,
						name: file.name,
						content: contentArch
					});

					var uploadedFile = files.shift();
					console.log("uploaded success:", uploadedFile.name);
				} catch (e) {
					console.log("falló: " + file.name);
				}
				return this._uploadImages(files);
			}

			return true;
		},
		remove(file, i) {
			console.log("remove index:", i, file);
			console.log("fileassoc:", this.filesAssoc);
			var removeFileName = false; //t

			removeFileName = this.filesAssoc[file.file.name];
			delete this.filesAssoc[file.file.name];

			this.removedFilesCount++;
			this.$refs.upload.remove(file.id);
			this.removeFiles.push(removeFileName);
			console.log("this.removeFiles", this.removeFiles);
		},
		inputFilter(newFile, oldFile, prevent) {
			if (newFile && !oldFile) {
				if (
					/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(
						newFile.name
					)
				) {
					return prevent();
				}
				// Filter php html js file
				// 过滤 php html js 文件
				if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
					return prevent();
				}
				// Automatic compression
				// 自动压缩
				if (
					newFile.file &&
					newFile.type.substr(0, 6) === "image/" &&
					this.autoCompress > 0 &&
					this.autoCompress < newFile.size
				) {
					newFile.error = "compressing";
					/*const imageCompressor = new ImageCompressor(null, {
						convertSize: Infinity,
						maxWidth: 512,
						maxHeight: 512
					});
					imageCompressor
						.compress(newFile.file)
						.then(file => {
							this.$refs.upload.update(newFile, {
								error: "",
								file,
								size: file.size,
								type: file.type
							});
						})
						.catch(err => {
							this.$refs.upload.update(newFile, {
								error: err.message || "compress"
							});
						});*/
				}
			}
			if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
				// Create a blob field
				// 创建 blob 字段
				newFile.blob = "";
				let URL = window.URL || window.webkitURL;
				if (URL && URL.createObjectURL) {
					newFile.blob = URL.createObjectURL(newFile.file);
				}
				// Thumbnails
				// 缩略图
				newFile.thumb = "";
				if (
					newFile.blob &&
					newFile.type.substr(0, 6) === "image/"
				) {
					newFile.thumb = newFile.blob;
				}
			}
		},
		// add, update, remove File Event
		inputFile(newFile, oldFile) {
			this.$emit("updateFile", newFile || false);

			if (newFile) {
				console.log("");
			}
			if (newFile && oldFile) {
				// update
				if (newFile.active && !oldFile.active) {
					// beforeSend
					// min size
					if (
						newFile.size >= 0 &&
						this.minSize > 0 &&
						newFile.size < this.minSize
					) {
						this.$refs.upload.update(newFile, {
							error: "size"
						});
					}
				}
				if (newFile.progress !== oldFile.progress) {
					// progress
				}
				if (newFile.error && !oldFile.error) {
					// error
				}
				if (newFile.success && !oldFile.success) {
					// success
				}
			}
			if (!newFile && oldFile) {
				// remove
				if (oldFile.success && oldFile.response.id) {
					// $.ajax({
					//   type: 'DELETE',
					//   url: '/upload/delete?id=' + oldFile.response.id,
					// })
				}
			}
			// Automatically activate upload
			if (
				Boolean(newFile) !== Boolean(oldFile) ||
				oldFile.error !== newFile.error
			) {
				if (this.uploadAuto && !this.$refs.upload.active) {
					this.$refs.upload.active = true;
				}
			}
		},
		alert(message) {
			alert(message);
		},
		onEditFileShow(file) {
			this.editFile = { ...file, show: true };
			this.$refs.upload.update(file, { error: "edit" });
		},
		onEditorFile() {
			if (!this.$refs.upload.features.html5) {
				this.alert("Your browser does not support");
				this.editFile.show = false;
				return;
			}
			let data = {
				name: this.editFile.name
			};
			if (this.editFile.cropper) {
				let binStr = atob(
					this.editFile.cropper
						.getCroppedCanvas()
						.toDataURL(this.editFile.type)
						.split(",")[1]
				);
				let arr = new Uint8Array(binStr.length);
				for (let i = 0; i < binStr.length; i++) {
					arr[i] = binStr.charCodeAt(i);
				}
				data.file = new File([arr], data.name, {
					type: this.editFile.type
				});
				data.size = data.file.size;
			}
			this.$refs.upload.update(this.editFile.id, data);
			this.editFile.error = "";
			this.editFile.show = false;
		},
		// add folader
		onAddFolader() {
			if (!this.$refs.upload.features.directory) {
				this.alert("Your browser does not support");
				return;
			}
			let input = this.$refs.upload.$el.querySelector("input");
			input.directory = true;
			input.webkitdirectory = true;
			this.directory = true;
			input.onclick = null;
			input.click();
			input.onclick = e => {
				console.log("e", e);
				this.directory = false;
				input.directory = false;
				input.webkitdirectory = false;
			};
		},
		onAddData() {
			this.addData.show = false;
			if (!this.$refs.upload.features.html5) {
				this.alert("Your browser does not support");
				return;
			}
			let file = new window.File(
				[this.addData.content],
				this.addData.name,
				{
					type: this.addData.type
				}
			);
			alert("xx");
			this.$refs.upload.add(file);
		}
	}
};
var getFileBlob = function(url, cb) {
	var xhr = new XMLHttpRequest();
	xhr.open("GET", url);
	xhr.responseType = "blob";
	xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
	xhr.setRequestHeader("Access-Control-Allow-Credentials", "true");

	xhr.addEventListener("load", function() {
		cb(xhr.response);
	});
	xhr.send();
};

var blobToFile = function(blob, name) {
	blob.lastModifiedDate = new Date();
	blob.name = name;
	return blob;
};

var getFileObject = function(filePathOrUrl, filename) {
	return new Promise(function(response) {
		getFileBlob(filePathOrUrl, function(blob) {
			response(blobToFile(blob, filename));
		});
	});
};

function handleFileSelect(f) {
	var reader = new FileReader();
	// Closure to capture the file information.

	return new Promise(function(resolve) {
		reader.onload = (function() {
			return function(e) {
				var binaryData = e.target.result;
				//Converting Binary Data to base 64
				var base64String = window.btoa(binaryData);
				//showing file converted to base64
				resolve(base64String);
			};
		})(f);
		// Read in the image file as a data URL.
		reader.readAsBinaryString(f);
	});
}

async function compressImage(file) {
	return new Promise(function(resolve, reject) {
		new Compressor(file, {
			width: 800,
			quality: 0.6,
			success(newFile) {
				console.log("newFile", newFile);
				resolve(newFile);
			},
			error(err) {
				reject(err);
			}
		});
	});
}
</script>



<style scoped>
.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
	border-collapse: collapse;
}
.table-responsive td {
	border-top: 0px none !important;
}
.example-full .btn-group .dropdown-menu {
	display: block;
	visibility: hidden;
	transition: all 0.2s;
}
.example-full .btn-group:hover > .dropdown-menu {
	visibility: visible;
}
.example-full label.dropdown-item {
	margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
	margin-right: 0.6rem;
}
.example-full .filename {
	margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
	margin-top: 0.25rem;
}
.example-full .example-foorer {
	padding: 0.5rem 0;
	margin-left: 25px;
}
.example-full .edit-image img {
	max-width: 100%;
}
.example-full .edit-image-tool {
	margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
	margin-right: 0.6rem;
}
.example-full .footer-status {
	padding-top: 0.4rem;
}
.example-full .drop-active {
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	position: absolute;
	z-index: 9999;
	opacity: 0.6;
	text-align: center;
	background: #000;
}
.example-full .drop-active h3 {
	margin: -0.5em 0 0;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 40px;
	color: #fff;
	padding: 0;
}
</style>
