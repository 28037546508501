import { mapGetters } from "vuex";
import FileUpload from "./../fileupload.vue";
import materialupload from "./../materialupload.vue";


var _subscribed = false;
export default {
     props: ['open', 'value'],
     components: { FileUpload, materialupload },
     data: (vm) => ({
          questionsSave: true,
          dialog_remove_cursos: false,
          selected_curso: false,
          removeFieldConfirm: '',
          
          rid:false,

          post_selected: false,
          events: [],
          dialog: false,
          isOpenForm: false,
          formedit: {},
          id: 0,
          links: [],
          items: [],
          temas: [],
          descripcion: '',
          categorias_readonly:false,
          place_readonly:false,
          title: '',
          tab: 0,
          menu: false,
          images: {
               bucket: "plm-afi",
               path: "post/images",
               prefix: "post_pic_",
               files: [],
               commit: 0
          },
          files: {
               bucket: "plm-afi",
               path: "post/material",
               prefix: "post_mat_",
               files: [],
               commit: 0
          },

          categorias: [],
          imagenes: [],
          place: [],
          archivos: [],
          tipo: '',
          save_files: 0,
          saving: false,



          modal_instructors: false,
          form_index: -1,
          rules: {
               required: [value => !!value || "Required."],
               counter: value => value.length <= 5 || "Max 20 characters",
               email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
               },

               nameRules: [
                    v => !!v || "Name is required",
                    v =>
                         (v && v.length <= 10) ||
                         "Name must be less than 10 characters"
               ]
          },
          valid: false,
          files_readed: false,
     }),
     computed: {
          getDepartamentos() {
               var departamentos = this.$store.getters['post/getDepartamentos']
               var _this =this;
               var placegeneral = _this.place.filter(p=>{
                    console.log('p',p);
                    return p.split(":").length < 3;
               })
               console.log('placegeneral',placegeneral);

               return departamentos.filter(d=>{
                    for(var x = 0;  x  < placegeneral.length ; x++){
                         if (d['value'].split(":").length > placegeneral[x].split(":").length){
                              return d['value'].indexOf(placegeneral[x])<0
                         }
                       
                    }
                    return true;
                    

               })

          },
          getTypes() {
               return ['Opinión', 'Datos', 'Sugerencia', 'Proyecto', 'Elección'];
          },
          getListCategorias() {
               return this.$store.getters['post/getCategories'].map(c => {
                    return {
                         value: c._t,
                         text: c._n,
                    }
               })
          },
          computedDateFormatted() {
               var _this = this;
               return this.dates.map(d => _this.formatDateCalendar(d))
          },
          ...mapGetters('instructores', ['getInstructores']),
          ...mapGetters('cursos', ['getCursos']),
          ...mapGetters('usuario', ['getUserLogged']),

     },

     watch: {
          place(place){

          },
          value: {
               handler(val) {
                    this.categorias_readonly = false;
                    this.place_readonly = false;
                    this.rid = false;
                    console.log('watch value',val);
                    if (typeof val == 'undefined') {
                         return;
                    }

                    if (val !== false && val.id.length > 3) {
                         this.pushSelectedValues(val);
                    }
                    else {
                         console.log("addpost");
                         this.addPost();
                    }
                    //
                    if (val.response && val.response!==false){
                         this.rid = val.response.id;
                         this.categorias = val.response.categorias;
                         this.place = val.response.place;
                         this.categorias_readonly = true;
                         this.place_readonly = true;                         
                    }


               },
               immediate: true

          },
          save_files(val) {
               if (val == 2) {
                    this.save_files = 0;
                    this.save();
               }
          },

          dialog(v) {
               if (v == false) {
                    this.reset();
               }
          }
     },

     mounted() {
          var _this = this;
          if (typeof _subscribed == 'function') {
               _subscribed();
          }
          _subscribed = this.$store.subscribe(({ type, payload }) => {
               if (type == 'post/saved') {
                    this.$emit('add');
                    _this.closeDialog();
                    this.dialog_remove_cursos = false;
               }
          });
     },
     methods: {
          closeDialog() {
               this.dialog = false;
               this.$emit('close');
          },
          readAllFiles(index) {
               this.files_readed = true;
          },

          reset() {
               console.log('reset');
               this.tab = 'tab-1';
               this.id = 0;
               this.title = '';
               this.temas = '';
               this.links = [];
               this.place = [];
               this.categorias = [];
               this.images.images = [];
               this.tipo = '';
               this.files.files = [];
               this.imagenes = [];
               this.archivos = [];
               this.descripcion = '';
               this.rid = false;
          },
          addPost(index) {
               this.reset();
          },


          addLink(index) {
               this.links.push({
                    name: '',
                    link: ''
               });
          },

          commitSave() {
               this.images.commit++;
               this.files.commit++;
          },
          pushSelectedValues(value) {
               console.log('pushSelectedValues value', value);
               this.id = value.id;
               this.title = value.titulo || '';
               this.tipo = value.type || '';
               this.temas = value.temas || [];
               this.place = value.place || [];
               this.links = value.links || [];
               this.categorias = value.categorias || [];
               this.descripcion = value.descripcion || '';
               this.images.files = value.imagenes || [];
               this.files.files = value.archivos;
               this.dialog = true;
          },
          save() {
               var user = this.$store.state.users.user;

               var place  = this.place || [];
               place = place.length==0?['Argentina']:place;

               
               console.log('user', user);
               var obj = {
                    author: {
                         dni:user.dni,
                         apellido:user.apellido,
                         nombre:user.nombre,
                         movil:user.movil,
                    },
                    id: this.id,
                    titulo: this.title || '',
                    type: 'Datos',
                    temas: this.temas || [],
                    place: this.place || [],
                    links: this.links || [],
                    categorias: this.categorias || [],
                    imagenes: this.imagenes || [],
                    archivos: this.archivos || [],
                    descripcion: this.descripcion || '',
                    tx1: this.rid,
               };

               this.$store.dispatch('post/save', obj)

          },

          removeLink(index) {
               this.links.splice(index, 1);
          },


          removeImages(files) {

          },
          saveArchivos(files) {
               console.log('archivos');
               this.archivos = files;
               this.save_files++;
          },
          saveImagenes(files) {
               console.log('saveImagenes');
               this.imagenes = files;
               this.save_files++;
          },

     },
}