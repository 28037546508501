<template>
	<div>
		<afiliados-add v-if="dialog=='afiliado_add'" :data="afiliado_edit" @close="closeAddAfiliado" />
		<afil v-if="afiliado" :afiliado="afiliado" @close="closeAfiliado" />
		<afil-datos v-if="datos" :afiliado="datos" @close="datos = false" @editar="editar" />
		<enviar v-if="penviar" :afiliado="penviar" @close="penviar = false" />
		<pic v-if="item_pic_selected" :item="item_pic_selected" @close="item_pic_selected=false" />

		<v-simple-table fixed-header style="padding-bottom:95px;">
			<template v-slot:default>
				<thead>
                         <tr>
						<th class="text-left" colspan="8">
							<span class="pageLabel">Paginas:</span>
							<span
								class="page-items"
								v-for="(pageList,i) in getPages"
								:key="i"
								@click="changePage(pageList)"
							>
								<span class="page-list" :class="{selectedPage:pageList.page==page}">{{pageList.page+1}}</span>
								<span class="page-count">{{pageList.count>0?"("+pageList.count+")":''}}</span>
							</span>
						</th>
					</tr>
					<tr>
						<th class="text-left">
							<v-btn text @click="sortAction('apellido')">Nombre</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('dni')">Dni</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('localidad')">Localidad</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('source')">Origen</v-btn>
						</th>
						<!--<th class="text-left">Ficha</th>-->
						<th class="text-left">Participa</th>
						<th class="text-left">images</th>
						<th class="text-left">Datos</th>
						<th class="text-left">enviar</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item,i) in getAfiliadosPerPage"
						:class="{
                                             red:item.colaboracion=='datos falsos/descartar',
                                             'green-text':item.ficha
                                        }"
						:key="i"
					>
						<td>
							{{i+1}}
							<v-btn :color="item.ficha?'green' : 'blue'" text small v-on:click="afiliado=item">
								<v-icon
									small
									color="yellow"
									v-if="item.usuario_id.length > 0 && item.usuario_id!=usuario_logged"
								>mdi-share-circle</v-icon>
								<v-icon small color="green" v-if="item.ficha_firma">mdi-check-decagram</v-icon>
								{{ item.apellido + ", "+item.nombre }} ({{item.movil}})}
							</v-btn>
						</td>
						<td>{{ item.dni }}</td>
						<td>{{ item.localidad }}</td>
						<td>{{ item.source }}</td>
						<td>{{ item.colaboracion }}</td>
						<td>
							<v-btn icon @click="showimages(item)">
								<v-icon>mdi-image</v-icon>
							</v-btn>
						</td>
						<td>
							<v-btn icon @click="datos=item">
								<v-icon>mdi-text-box-outline</v-icon>
							</v-btn>
						</td>
						<td>
							<v-btn icon @click="penviar=item">
								<!--<v-icon>mdi-map-marker</v-icon>-->
								<v-icon>mdi-share</v-icon>
							</v-btn>
						</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		<v-dialog persistent v-model="info" width="500" transition="dialog-bottom-transition">
			<v-card color="#1A237E">
				<v-card-title>FILTROS</v-card-title>
				<v-card-text>
					<v-simple-table dense dark style="background : #1A237E">
						<template slot:default>
							<tr>
								<td>Total</td>
								<td>
									<v-checkbox
										dense
										:label=" '(' + count.total + ') '"
										@change="changeFilter('total')"
										v-model="f_total"
									/>
								</td>
							</tr>
							<tr>
								<td>firmadas</td>
								<td>
									<v-radio-group v-model="filter.signed" dense>
										<v-radio :label="'Todas'" :value="-1"></v-radio>
										<v-radio :label="'Firmadas ('+count.signed +')'" :value="1"></v-radio>
										<v-radio :label="'No firmadas ('+count.unsigned +')'" :value="0"></v-radio>
									</v-radio-group>
								</td>
							</tr>
							<tr>
								<td>Llenadas</td>
								<td>
									<v-radio-group v-model="filter.filled" dense>
										<v-radio :label="'Todas'" :value="-1"></v-radio>
										<v-radio :label="'Llenas ('+count.filled +')'" :value="1"></v-radio>
										<v-radio :label="'A llenar ('+count.unfilled +')'" :value="0"></v-radio>
									</v-radio-group>
								</td>
							</tr>
							<tr>
								<td>Fichas derivadas</td>
								<td>
									<v-radio-group v-model="filter.sended" dense>
										<v-radio :label="'Todas'" :value="-1"></v-radio>
										<v-radio :label="'Derivadas ('+count.sended +')'" :value="1"></v-radio>
										<v-radio :label="'No Derivadas ('+count.unsended +')'" :value="0"></v-radio>
									</v-radio-group>
								</td>
							</tr>
							<tr>
								<td>Ocultar inhabilitados</td>
								<td>
									<v-checkbox
										dense
										:label=" '(' + count.inhabilitados + ') '"
										@change="changeFilter('habilitados')"
										v-model="f_disabled"
									/>
								</td>
							</tr>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="info=false" color="primary">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-btn class="add-button" color="pink" dark fixed top right small fab @click="addNew">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-btn class="info-button" color="blue" dark fixed top right small fab @click="info=true">
			<v-icon>mdi-filter</v-icon>
		</v-btn>
	</div>
</template>

<script>
import afiliadosAdd from "./afiliado_form_add.vue";
import afilDatos from "./datos.vue";
import afil from "./afil.vue";
import enviar from "./enviar.vue";
import pic from "./pic.vue";

export default {
	components: { afil, afilDatos, afiliadosAdd, enviar, pic },
computed: {
		getPages() {
			var afLength = this.afiliados.length;
			var count = afLength / this.limitxpage;
			var pages = [];
			for (var i = 0; i < count; i++) {
				var afiliadosPage = this.afiliados.slice(
					i * this.limitxpage,
					i * this.limitxpage + this.limitxpage
				);
				var coutafil = 0;
				for (
					var t = 0, countAfiliadosPage = afiliadosPage.length;
					t < countAfiliadosPage;
					t++
				) {
					if (
						typeof this.selected[afiliadosPage[t]["dni"]] ===
						"undefined"
					) {
						continue;
					}
					if (this.selected[afiliadosPage[t]["dni"]]) {
						coutafil++;
					}
				}

				pages.push({
					count: coutafil,
					page: i
				});
			}
			console.log("pages", pages);
			return pages;
		},
		getAfiliadosPerPage() {
			return this.afiliados.slice(
				this.page * this.limitxpage,
				this.page * this.limitxpage + this.limitxpage
			);
		}
	},
	
	props: {
		dni: {
			default: ""
		},
		departamento: {
			default: ""
		},
		assigned: {
			default: false
		}
	},

	watch: {
		assigned: {
			handler(val) {
				console.log("assigned", val);
				this.init();
			},
			immediate: true
		},
		departamento(val) {
			this.filtered(val);
			this.drawer = false;
		},
		dni(val) {
			this.filtered(this.departamento);
		},
		filter: {
			handler(actual, prev) {
				if (
					actual.signed == -1 ||
					actual.filled == -1 ||
					actual.sended == -1
				) {
					this.f_total = true;
				}
				if (
					actual.signed != -1 ||
					actual.filled != -1 ||
					actual.sended != -1
				) {
					this.f_total = false;
				}
				this.filtered(this.departamento);
			},
			deep: true
		}
	},

	data() {
		return {
			page: 0,
			limitxpage: 100,

			f_disabled: true,
			f_total: true,
			filter: {
				signed: -1,
				filled: -1,
				sended: -1
			},
			count: {
				inhabilitados: 0,
				total: 0,
				signed: 0,
				unsigned: 0,
				filled: 0,
				unfilled: 0,
				sended: 0,
				unsended: 0
			},

			info: false,
			dialog: false,
			datos: false,
			penviar: false,
			dato: false,
			penviar: false,

			sort: false,
			afiliados: [],
			afiliado: false,
			item_pic_selected: false,
			b_assigned_items: false,
			afiliado_edit: false,
			usuario_logged: "",
               level: 0,
               
               selected: {}
		};
	},
	mounted() {
		console.log("mounted init");
		//	this.init();
	},
	methods: {
          changePage(pag) {
			this.page = pag.page;
		},
		editar(datos) {
			var datos = JSON.parse(datos);
			this.datos = false;
			this.openAfiliadoAdd();
			console.log("editar", datos);
			this.afiliado_edit = datos;
		},
		changeFilter(key) {
			if (key == "habilitados") {
				if (this.f_disabled) {
				}
			}
			if (key == "total") {
				if (this.f_total) {
					this.filter = {
						signed: -1,
						filled: -1,
						sended: -1
					};
				} else {
				}
			}
			this.filtered(this.departamento);
		},
		async init() {
			var permisos = this.$store.state.afiliaciones.permisos;
			this.b_assigned_items = this.assigned;
			this.usuario_logged = this.$store.state.afiliaciones.userds;
			this.level = permisos.level;

			await this.reload();
			this.afiliados = this.$store.state.afiliaciones.afiliaciones;
			this.filtered(this.departamento);
		},
		sortAction(keysort) {
			var sort = keysort;
			this.sort = keysort;
			this.afiliados.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
		},
		openAfiliadoAdd() {
			this.prev_modulo = this.modulo;
			this.dialog = "afiliado_add";
			this.pldistmap = false;
		},
		async closeAddAfiliado(reload) {
			if (reload) {
				await this.reload();
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
				this.afiliado = this.afiliados[this.afiliados.length - 1];

				this.filtered(this.departamento);
			}

			this.dialog = "";
		},
		addNew() {
			this.openAfiliadoAdd();
		},
		async reload() {
			await this.$store.commit("iface/setWait", { wait: true });
			var permisos = this.$store.state.afiliaciones.permisos;
			if (!this.b_assigned_items || this.b_assigned_items < 0) {
				await this.$store.dispatch(
					"afiliaciones/fetchAfiliaciones",
					{
						departamentos: this.$store.state.afiliaciones.departamentos.map(
							x => x.trim()
						)
					}
				);
			}
			if (this.b_assigned_items > 0) {
				await this.$store.dispatch(
					"afiliaciones/fetchAfiliaciones",
					{ usuario: this.$store.state.afiliaciones.userds }
				);
			}
			await this.$store.commit("iface/setWait", { wait: false });
			return true;
		},

		async filtered(departamento) {
			var sort = this.sort || false;
			if (this.b_assigned_items > 0) {
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
			}
			if (this.b_assigned_items < 0) {
				this.afiliados = this.$store.state.afiliaciones.afiliaciones.sort(
					(x, y) => (x["id"] > y["id"] ? -1 : 1)
				);
			}
			if (!this.b_assigned_items) {
				this.afiliados = this.$store.state.afiliaciones.afiliaciones.filter(
					a => a.departamento_vive == departamento
				);
			}

			this.count = {
				total: 0,
				signed: 0,
				unsigned: 0,
				filled: 0,
				unfilled: 0,
				inhabilitados: 0,
				sended: 0,
				unsended: 0
			};

			for (var i = 0; i < this.afiliados.length; i++) {
				if (
					this.afiliados[i].colaboracion ==
					"datos falsos/descartar"
				) {
					this.count.inhabilitados++;
				}
				if (this.afiliados[i].ficha == 1) {
					this.count.filled++;
				} else if (this.afiliados[i].ficha == 0) {
					this.count.unfilled++;
				}

				if (this.afiliados[i].ficha_firma > 0) {
					this.count.signed++;
				} else if (this.afiliados[i].ficha_firma == 0) {
					this.count.unsigned++;
				}

				if (
					this.afiliados[i].usuario_id.length > 0 &&
					this.afiliados[i].usuario_id != this.usuario_logged
				) {
					this.count.sended++;
				} else {
					this.count.unsended++;
				}

				this.count.total++;
			}

			var filterAssoc = JSON.parse(JSON.stringify(this.filter));

			var _this = this;

			this.afiliados = this.afiliados.filter(a => {
				var res = false;
				if (
					this.f_disabled &&
					a.colaboracion == "datos falsos/descartar"
				) {
					return false;
				}
				if (this.f_total) {
					return true;
				}

				if (filterAssoc["signed"] == 0 && a.ficha_firma > 0) {
					return false;
				} else if (
					filterAssoc["signed"] == 1 &&
					a.ficha_firma == 0
				) {
					return false;
				}

				if (filterAssoc["filled"] == 0 && a.ficha > 0) {
					return false;
				} else if (filterAssoc["filled"] == 1 && a.ficha == 0) {
					return false;
				}

				if (
					filterAssoc["sended"] > 0 &&
					(a.usuario_id == this.usuario_logged ||
						a.usuario_id.length == 0)
				) {
					return false;
				} else if (
					filterAssoc["sended"] == 0 &&
					a.usuario_id != this.usuario_logged &&
					a.usuario_id.length > 0
				) {
					return false;
				}

				return true;
			});

			if (this.dni.length > 0) {
				this.afiliados = this.afiliados.filter(
					a => a.dni.toString().indexOf(this.dni) > -1
				);
			}

			if (sort) {
				this.afiliados.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
               }
               
               console.log('filter',this.afiliados);
		},
		async closeAfiliado(reload) {
			this.afiliado = false;
			this.plmap = false;
			if (reload) {
				await this.reload();
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
				this.filtered(this.departamento);
			}
		},
		async open(reload) {
			this.afiliado = false;
			if (reload) {
				await this.reload();
				this.afiliados = this.$store.state.afiliaciones.afiliaciones;
				this.filtered(this.departamento);
			}
		},
		showimages(item) {
			var file1 =
				"https://plafiliaciones.s3.sa-east-1.amazonaws.com/images/" +
				item["file1"]["file"];
			var file2 =
				"https://plafiliaciones.s3.sa-east-1.amazonaws.com/images/" +
				item["file2"]["file"];
			this.item_pic_selected = {
				file1: file1,
				file2: file2,
				title:
					item.nombre +
					" " +
					item.apellido +
					"(" +
					item.movil +
					")"
			};
		}
	}
};
</script>
<style scoped>

.page-list.selectedPage {
	color: white;
	text-decoration: none;
}
.page-count {
	font-size: 12px;
	margin-right: 10px;
	width: 100%;
	color: red;
	width: 100%;
	float: left;
	text-align: center;
}
.page-list {
	cursor: pointer;
	font-size: 14px;
	margin-right: 10px;
	color: aqua;
	width: 100%;
	text-decoration: underline;
	float: left;
	text-align: center;
}
.pageLabel {
	font-size: 14px;
	margin-right: 10px;
	color: white;
	float: left;
}
.page-items {
	float: left;
}

</style>