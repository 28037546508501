import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../components/dashboard/dashboard.vue'
import Afiliados from '../components/afiliados/afiliados.vue'
import Ref from '../components/referidos.vue'
import Principios from '../components/front/front.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/a/:ref',
    name: 'refer',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vote',
    name: 'vote',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vote.vue')
  },
  {
    path: '/init',
    name: 'init',
    component: () => import(/* webpackChunkName: "about" */ '../views/Init.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/categories/:category',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/afiliados',
    name: 'afiliados',
    component: Afiliados
  },
  {
    path: '/post/:post',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/ref',
    name: 'ref',
    component: Ref
  },
  {
    path: '/home',
    name: 'ref',
    component: Principios
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
