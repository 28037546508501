<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500" :fullscreen="isMobile">
			<v-form ref="form_user" v-model="valid" :lazy-validation="false">
				<v-card>
					<v-card-title class="headline blue darken-2">{{nombre}}</v-card-title>

					<v-card-text style="height: 80hv;">
						<v-container>
							<v-alert v-if="success" type="success">Usuario guardado</v-alert>
							<v-row>
								<v-col cols="12">
									Nombre:
									<v-text-field :disabled="user!==false" dense outlined v-model="nombre" :rules="[v => testUser()||'Nombre de usuario utilizado']" />
                                             
                                             
                                             Contraseña
									<v-text-field
                                                  
										required
										:rules="[v => (v.length >= 4 && user==false || ((v.length==0 || v.length >= 4)  && user!==false) )  || 'Ingresa 4 caracteres mínimo']"
										type="password"
										dense
										outlined
										v-model="pass1"
									/>
                                             
                                             
                                             Contraseña
									<v-text-field
										required
										:rules="[
                                             v => v==pass1 || 'Debes repetir la misma contraseña',
                                             ]"
										type="password"
										dense
										outlined
										v-model="pass2"
									/>

									<v-checkbox v-if="!logged_user" dense v-model="assigned" label="Solo afiliados asignados"></v-checkbox>

									<div v-show="!assigned" v-if="!logged_user">
										Departamentos
										<v-select dense outlined :items="departamentos" multiple v-model="departamento"></v-select>
									</div>



                                             <v-checkbox v-if="!logged_user" dense v-model="disabled" label="Deshabilitar"></v-checkbox>
								</v-col>
							</v-row>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-btn class="default" text @click="close">Cerrar</v-btn>
						<v-spacer />
						<v-btn class="primary" v-if="!success" :disabled="!valid" @click="save">Guardar</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
	</div>
</template>
<script>
var dep = "Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle"
	.split(",")
	.sort();

export default {
	props: {
		user: {
			defalut: false
		}
	},
	data() {
		return {
			success: false,
			valid: false,
			nombre: "",
			pass1: "",
			pass2: "",
			disabled: false,
			dialog: true,
			departamentos: dep,
			departamento: "",
               assigned: false,
               logged_user:false,
               usersList : []
		};
	},
	computed: {
		isMobile() {
			return (
				this.$vuetify.breakpoint.mobile || window.innerWidth < 700
			);
          },
          
	},
	watch: {
          user:{
               handler(val){
                    console.log('user watch',val);
               },
               immediate:true
          }
     },
	mounted() {
		//this.nombre = this.$store.state.afiliaciones.userds;

		if (this.user) {
			this.fill();
		} else {
               this.nombre = "";
               this.usersList = this.$store.state.afiliaciones.users.map(x=>x.username);
		}
	},
	methods: {
          testUser(){
               
               if (this.user==false){
                    if (this.usersList.indexOf(this.nombre)>-1){
                         return false;
                    }
               }
               return true;
          },
		fill() {
               console.log('this.user',this.$store.state.afiliaciones.userds,this.user.username);
               this.logged_user = false;
               if (this.$store.state.afiliaciones.userds==this.user.username){
                    this.logged_user = true;
               }
			this.nombre = this.user.username;
			this.departamento = this.user.departamentos.split(",");
			this.assigned = this.user._level == 1;
			this.disabled = this.user._disabled > 0;
		},
		close() {
			this.$emit("close", false);
		},
		async save() {
			this.valid = false;

			var optsend = {
				user: this.nombre,
				password: this.pass1,
				departamentos: this.departamento
					.filter(x => x.length > 0)
					.join(","),
				assigned: this.assigned ? 1 : 0,
				disabled: this.disabled ? parseInt(new Date().getTime()) : 0,
                    parent: this.$store.state.afiliaciones.userds,
                    newuser:false
			};
			if (this.user) {
				optsend.user = this.user.username;
				optsend.newuser = true;
			}
			await this.$store.dispatch("afiliaciones/saveUser", optsend);
			this.$emit("close", true);
			this.success = true;
		}
	}
};
</script>