<template>
	<div>
		<usuario v-if="dialog=='user_add'" :user="user_data" @close="closeUsuario" />

		<v-simple-table fixed-header style="padding-bottom:95px;">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">
							<v-btn text @click="sortAction('username')">Nombre</v-btn>
						</th>
						<th class="text-left">
							DEPARTAMENTOS
						</th>
						<th class="text-left">
							Solo usuarios asignados
						</th>
						<!--<th class="text-left">Ficha</th>-->
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item,i) in usuarios"
						:key="i"
                              :class="{
                                   disabled : item._disabled
                              }"
					>
						<td>
                                   <v-btn color="blue" text @click="editUser(item)">{{item.username}}</v-btn>
						</td>
						<td>
                                   {{item.departamentos}}
						</td>
						<td>
                                  <v-simple-checkbox dense  :value="item._level==1" ></v-simple-checkbox>
						</td>
						
					</tr>
				</tbody>
			</template>
		</v-simple-table>

		

		<v-btn class="add-button" color="pink" dark fixed top right small fab @click="addNew">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
	</div>
</template>

<script>
import usuario from "./user.vue";

export default {
	components: { usuario },


	watch: {

	},

	data() {
		return {
			dialog: false,
			penviar: false,

			sort: false,
			usuarios: [],
			user_data: false,
		};
	},
	mounted() {
		this.init();
	},
	methods: {
        
          editUser(item){
               
			this.dialog = "user_add";
               this.user_data = JSON.parse(JSON.stringify(item));
          },
          addNew(){
               
			this.dialog = "user_add";
               this.user_data = false;
          },
         
		async init() {
			this.usuario_logged = this.$store.state.afiliaciones.userds;

			await this.reload();
		},
		sortAction(keysort) {
			var sort = keysort;
			this.sort = keysort;
			this.afiliados.sort((a, b) => (a[sort] < b[sort] ? -1 : 1));
		},
		openUserAdd() {
		
		},
		
		async reload() {
			await this.$store.commit("iface/setWait", { wait: true });
			var permisos = this.$store.state.afiliaciones.permisos;

			
               this.usuarios =  (await this.$store.dispatch('afiliaciones/fetchUsers'));

			
			await this.$store.commit("iface/setWait", { wait: false });
			return true;
		},

		
		async closeUsuario(reload) {
               this.usuario = false;
               this.user_data = false;
               this.dialog = '';
               console.log("closeuser");
			if (reload) {
                    console.log("reload");
				await this.reload();
			}
		},
		async open(reload) {
			this.usuario = false;
			if (reload) {
				await this.reload();
				this.usuarios = this.$store.state.afiliaciones.usuarios;
			}
		},
		
	}
};
</script>
<style scoped>
.disabled {
     background:darkred;;
}
.disabled button{
     color: white !important;
     
}
</style>