


const state = {
     mod_instructores: false,
     wait : '',
     text : '',
     changed:0
}
const mutations = {
     openModalInstructor(state,val){
          state.mod_instructores = val;
     },
     setWait(state, {wait,text}) {
          state.wait = wait;
          state.text = text;
          state.changed++;
     },
}
const actions = {
}
const getters = {
     getWait(state){
          if (state.changed){
               return {
                    wait : state.wait,
                    text : state.text
               }
          }
          
     },
     isModalInstructoOpen(state){
          return state.mod_instructores;

     }
}




export default {
     namespaced: true,
     state, getters, actions, mutations
}

