<template>
     <div class="text-center">
		<v-dialog persistent v-model="dialog" width="100%" >
			<v-card>
                    <v-card-title>{{item.title}}</v-card-title>
                    <v-card-text>
                         <v-row>
                              <v-col  v-if="visible1" :cols="colval" class="cl-relative">
                                   <div class="rotate-image" @click="rotate(0)">
                                        <v-icon>mdi-reload</v-icon>
                                   </div>
                                   <div class="maximize-image" @click="maximize(0)">
                                        <v-icon>mdi-window-maximize</v-icon>
                                   </div>
                                   <v-img :class="'rotate'+rotate1" @click="open(item.file1)" :src="item.file1"></v-img>
                              </v-col>
                              <v-col v-if="visible2" :cols="colval" v class="cl-relative">
                                   <div class="rotate-image" @click="rotate(1)">
                                        <v-icon>mdi-reload</v-icon>
                                   </div>
                                   <div class="maximize-image" @click="maximize(1)">
                                        <v-icon>mdi-window-maximize</v-icon>
                                   </div>
                                   <v-img :class="'rotate'+rotate2" @click="open(item.file2)" :src="item.file2"></v-img>
                              </v-col>
                         </v-row>
                    </v-card-text>
                    <v-card-actions>
                         <v-btn text @click="close">Cerrar</v-btn>
                    </v-card-actions>
			</v-card>
		</v-dialog>
     </div>

</template>

<script>
export default {
     props:['item'],
     data(){
          return {
               colval:6,
               rotate1:0,
               rotate2:0,
               visible1:true,
               visible2:true,
               dialog:true
          }
     },
     methods:{
          rotate(val){

               if (val==0){
                    this.rotate1+=90;
                    if (this.rotate1==360){
                         this.rotate1=0;
                    }
               }
               if (val==1){
                    this.rotate2+=90;
                    if (this.rotate2==360){
                         this.rotate2=0;
                    }
               }

          },
          maximize(val){
               this.colval = this.colval==6 ? 12 : 6;
               if (val==0){
                    if (this.colval==6){
                         this.visible1 = true;
                         this.visible2 = true;
                    }
                    else{
                         this.visible1 = true;
                         this.visible2 = false;
                    }
                    
               }
               if (val==1){
                    if (this.colval==6){
                         this.visible1 = true;
                         this.visible2 = true;
                    }
                    else{
                         this.visible1 = false;
                         this.visible2 = true;
                    }
               }

          },
          close(){
               this.dialog = false;
               this.$emit('close');
          },
          open(file){
               window.open( file, "_blank");
          }
     }
}
</script>
<style scoped>
.rotate-image{
     position:absolute;
     top:10px;
     left:10px;
     width:40px;
     height:40px;
     z-index: 100;
}
.maximize-image{
     position:absolute;
     top:10px;
     right:10px;
     width:40px;
     height:40px;
     z-index: 100;
     background:black;
}
.rotate90{
     transform: rotate(90deg);
}
.rotate180{
     transform: rotate(180deg);
}
.rotate270{
     transform: rotate(270deg);
}
.cl-relative{
     position: relative;
}
</style>