<template>
	<div class="text-center">
		<v-overlay :value="overlay" class="waiting">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
	</div>
</template>


<script>
import { mapGetters } from 'vuex';
export default {
	data: () => ({
		overlay: false,
		text: "Procesando..."
     }),
     computed:{
          ...mapGetters('iface',['getWait'])
     },
	watch: {
		getWait(val) {
			this.overlay = val.wait;
			this.text = val.text;
		}
     },
     mounted(){
     }
};
</script>