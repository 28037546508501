
export default {
     test(){
          alert("x1");
     },

     setCookie  (cname, cvalue, exdays) {
          var d = new Date();
          console.log("exdays * 24 * 60 * 60 * 1000",exdays,exdays * 24 * 60 * 60 * 1000);
          d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
          var expires = "expires=" + d.toUTCString();
          document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
     },
     getCookie  (cname) {
          var name = cname + "=";
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(';');
          for (var i = 0; i < ca.length; i++) {
               var c = ca[i];
               while (c.charAt(0) == ' ') {
                    c = c.substring(1);
               }
               if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
               }
          }
          return "";
     }
}