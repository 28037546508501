<template v-cloack>
	<v-card :max-width="1012" :class="post.css_type" class="mt-5">
		<v-btn @click="editPost(post)" absolute dark fab top right color="gray" style="z-index: 1000; ">
			<v-icon>mdi-pencil</v-icon>
		</v-btn>

		<v-dialog v-model="rating_dialog" width="400px">
			<v-card>
				<v-card-title class="headline">Confirma tu calificación</v-card-title>

				<v-card-text>Es muy importante para nosotros saber tu opinión de los contenidos para darle mas preponderancia y seguimiento a los contenidos mejores valorados</v-card-text>
				<v-card-text>
					<v-rating
						v-model="rating"
						:readonly="rating_readonly"
						:length="5"
						color="red lighten-3"
						background-color="grey lighten-1"
					></v-rating>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="green darken-1" text @click="ratingCancel()">Cancelar</v-btn>

					<v-btn color="green darken-1" outlined @click="ratingConfirm()">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row>
			<v-col md="8">
				<v-card-title>
					<a
						class="post-card-cats"
						color="blue"
						dense
						v-for="(cat,x) in post['categorias']"
						:key="x"
						text
					>{{cat}}</a>
					<div class="title_line1" @click="openPost">{{post['titulo']}}</div>
					<div class="title_line2">
						<v-avatar color="grey lighten-2" size="24px">
							<v-icon size="16px">mdi-pencil</v-icon>
						</v-avatar>
						{{post['author']['data']['nombre']}} {{post['author']['data']['apellido']}}
					</div>
				</v-card-title>
				<v-card-text @click="openPost">
					<v-carousel height="300">
						<v-carousel-item
							:key="i"
							reverse-transition="fade-transition"
							transition="fade-transition"
							v-for="(item, i) in post['imagenes']"
						>
							<v-img
								:max-width="600"
								:src="'https://'+bucket+'.s3.amazonaws.com/' + path + '/' + item.file"
								:aspect-ratio="1"
							></v-img>
						</v-carousel-item>
					</v-carousel>
				</v-card-text>
				<v-card-text class v-html="post['descripcion']"></v-card-text>
			</v-col>
			<v-col md="4">
				<v-card flat :max-width="600" class>
					<v-card-title></v-card-title>
					<v-card-text>Puntutación</v-card-text>
					<v-card-text>
						<v-rating
							v-model="rating"
							:readonly="rating_readonly"
							:length="5"
							color="red lighten-3"
							dense
							background-color="grey lighten-1"
						></v-rating>
						<div class="mt-5">
							<div v-for="(rat,i) in ratings" :key="i" class="line-score">
								<v-row>
									<v-col cols="1">
										<label class="label-score">{{(5-i)}}</label>
									</v-col>
									<v-col cols="11">
										<div :style="{width:rat+'%'}" :class="'rat-div' +' color-'+(i+1)"></div>
									</v-col>
								</v-row>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-card-actions v-if="is_mounted">
			<v-container>
				<v-row class="pt-0 pb-0">
					<v-col offset="1" cols="11" class="pt-0 pb-0" v-for="(ans,k) in post['ans_post']" :key="k">
						<item-post @edit="editPost" @add="addNew" :post="ans"></item-post>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" class="pt-0 pb-0">
						<v-row class="pt-0">
							<v-col class="pt-0">
								<v-btn icon right class="float-right pt-0" @click="callResponse">
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-card-actions>
	</v-card>
</template>
<script>

export default {
	name: "itemPostDatos",
	props: ["post"],
	watch: {
		rating(val) {
			if (this.rating_voted) {
				return;
			}
			if (val > 0) {
				this.rating_dialog = true;
			}
		}
	},
	data() {
		return {
			is_mounted: false,
			assocPostType: {
				Datos: "itemPostDatos"
			},
			ratings: [5, 4, 3, 2, 1],
			post_dialog: false,
			rating_readonly: false,
			rating_dialog: false,
			rating_voted: false,
			rating: 0,

			bucket: "plm-afi",
			path: "post/images"
		};
	},
	computed: {
		count_response() {
			var ans = this.post["ans"] || [];
			if (ans.length == 0) {
				return "";
			} else if (ans.length == 1) {
				return "1 respuesta";
			} else {
				return ans.length + " respuestas";
			}
		}
	},
	mounted() {
		var ratings = this.post.ratings || [];
		var user = this.$store.state.users.user;
		var dni = user["dni"];
		var total = 0;

		var ratins_scors = {
			"0": 0,
			"1": 0,
			"2": 0,
			"3": 0,
			"4": 0,
			"5": 0
		};
		for (var i = 0; i < ratings.length; i++) {
			if (ratings[i]["user"] == dni) {
				this.rating_voted = true;
			}

			ratins_scors[ratings[i]["rating"]]++;
			total += ratings[i]["rating"];
		}
		console.log("ratins_scoxxrsxax", ratins_scors, ratings.length);
		var rat_push = [];
		if (ratings.length) {
			//ratins_scors[i] = ratins_scors[i] / ratings.length * 100;
			for (var i = 1; i <= 5; i++) {
				rat_push.push((ratins_scors[i] / ratings.length) * 100);
			}
		}
		//ratins_scors.shift();
		//ratins_scors = ratins_scors.reverse()
		console.log("ratins_scccors", ratins_scors, rat_push);
		var total = total / ratings.length;
		if (this.rating_voted) {
			this.rating = total;
			this.rating_readonly = true;
		}

		this.ratings = rat_push.reverse();

		var _this = this;
		//this.is_mounted = true;
		setTimeout(x => {
			console.log("this.post_datos");
			_this.is_mounted = true;
			console.log("this.post_datos---");
		}, 500);
		console.log("--------------------------");
	},
	methods: {
		openPost() {
               this.post_dialog = true;
               this.$emit('show',this.post);
		},
		addNew(data) {
			this.$emit("add", data);
		},
		editPost(post) {
			this.$emit("edit", post);
		},
		callResponse() {
			console.log("call");
			this.$emit("add", ["Datos"], this.post);
		},
		ratingCancel() {
			this.rating = 0;
			this.rating_dialog = false;
		},
		ratingConfirm() {
			this.rating_dialog = false;
			this.rating_readonly = true;
			var user = this.$store.state.users.user;
			console.log("user", user);
			this.$store.dispatch("post/addRating", {
				rating: this.rating,
				post: this.post,
				user: user["dni"]
			});
		}
	}
};
</script>
<style scoped>
.max-description {
	height: 48px;
	overflow: hidden;
}

.read-more-description {
	height: 35px;
	padding: 3px;
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.58) 17%,
		rgba(255, 255, 255, 1) 58%,
		rgba(255, 255, 255, 1) 100%
	) !important;
}
/*
.rat-5 {
	width: 100%;
}
.rat-4 {
	width: 80%;
}
.rat-3 {
	width: 60%;
}
.rat-2 {
	width: 40%;
}
.rat-1 {
	width: 20%;
}
*/
.color-1 {
	background: #57bb8a;
}
.color-2 {
	background: #9ace6a;
}
.color-3 {
	background: #ffcf02;
}
.color-4 {
	background: #ff9f02;
}
.color-5 {
	background: #ff6f31;
}
.label-score {
	float: left;
	width: 25px;
}
.line-score {
	width: 100%;
	display: block;
	height: 25px;
	margin-top: 10px;
}
.rat-div,
.rat-5,
.rat-4,
.rat-3,
.rat-2,
.rat-1 {
	clear: both;
	height: 25px;
	float: left;
	padding-left: 3px;
}
</style>