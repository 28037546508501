<template>
	<div>
		<cform v-if="colaboracion" :colaborador="colaboracion" @close="closecolaboracion" />
		<v-simple-table fixed-header class="fill-height" style="padding-bottom:95px;">
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">
							<v-btn text @click="sortAction('apellido')">Nombre</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('departamento')">Departamento</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('detalle')">Detalle</v-btn>
						</th>
						<th class="text-left">
							<v-btn text @click="sortAction('fecha')">Fecha</v-btn>
						</th>
						<th class="text-left">Telefono</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item,i) in colaboraciones" :key="i">
						<td>
							<v-btn color="blue" text v-on:click="clickColaborador(item)">{{ item.nombre }}</v-btn>
						</td>
						<td>{{ item.tipo }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
	</div>
</template>

<script>
import cform from "./colab_form.vue";

export default {
	components: { cform },
	props: ["departamento"],
	data() {
		return {
               opciones:[],
			colaboracion: false,
			colaboraciones: []
		};
	},
	watch: {
		departamento: {
			handler(val) {
				this.update(val);
			},
			immediate: true
		}
	},
	mounted() {
		//this.update();
	},
	methods: {
		clickColaborador(item) {
			this.colaboracion = item;
		},
		closecolaboracion(value) {
               if (value){
                    this.update();     
               }
			this.colaboracion = false;
		},
		sortAction() {},
		async update(provincia) {
               this.$store.commit("iface/setWait", { wait: true });
			await this.$store.dispatch(
				"afiliaciones/fetchColaboracionRds",
				provincia
               );

               this.colaboraciones = [];
               for(var key in this.$store.state.afiliaciones.colaboracion){
                    for(var i = 0 ; i < this.$store.state.afiliaciones.colaboracion[key].length ; i++){
                         this.colaboraciones.push({
                              "nombre" :      this.$store.state.afiliaciones.colaboracion[key][i]['users']['name'],
                              "data" :      this.$store.state.afiliaciones.colaboracion[key][i],
                              "tipo" :      key
                         });
                    }
               }
               
               console.log('this.colaboraciones',this.colaboraciones);
               this.$store.commit("iface/setWait", { wait: false });

		}
	}
};
</script>