<template>
	<vForm>
		<wait />
	</vForm>
</template>



<script>
import vForm from "./reg/reg.vue";
import wait from "./wait.vue";

export default {
	components: { vForm, wait },
	name: "InitTemplate",

	data: () => ({}),
	mounted() {}
};
</script>

<style scoped>
.maxwidth {
	max-width: 100% !important;
	height: 100%;
}
</style>
