var enc = require('simple-encryptor');
var _KPA = '2vb17oDC7bWdvITERXqi2ynpcSEbnIg9fFI68Qe4';
var _TOKEN = '';
import util from './../util.js';

var _ENC = null;



const __VARS = [252, 441, 349, 119, 151, 269, 58, 399, 416, 329, 147, 465, 433, 222, 121, 84, 353, 438, 281, 407, 373, 120, 348, 71, 56, 362, 266, 183, 447, 292];
const __JT_BO = __VARS[14];
const __AR_XD = Math.ceil(__JT_BO / 5);





//import $ from 'jquery'
const URL_GET = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/user';
const URL_GET_PASS = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/p/test';
const URL_SEND_PASS = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/p/send';
const URL_BLOCK_PASS = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/p/block';


const state = {
     afiliacion: {},
     token: '',
     isExistUser: 0,
     user: {}
};

const getters = {
     isExistUser() {
          return state.isExistUser;
     }
}


const mutations = {

     logged(state, logged) {
          var val = new Date().getTime();
          state.isExistUser = logged ? val : val * -1;
     },
     saved() {

     },
     confirmCode() {
     },
     setToken(state, token) {
          state.token = token;
     },
     sendedCode() {

     },
     setUser(state, user) {
          state.user = user;
     },
     renewToken(state) {

     }

}

const actions = {
     init: async (context) => {
          var c = util.getCookie('init_');
          try {


               if (c.length > 10) {

                    var res = JSON.parse(c);
                    console.log('res', res);
                    var afiliado_ocupacion = res.key.substr(__JT_BO, __AR_XD)

                    _ENC = enc(afiliado_ocupacion);

                    _TOKEN = res.token;

                    return;
               }

          }
          catch (e) {
               console.log("errinit",e.stack);

          }
          var url = 'https://vb7ci87wq4.execute-api.us-east-1.amazonaws.com/dev/register';

          var sendata = {
               "_a": "l1rc",
               "_77qid82": "kAR",
               "_9rrg": "QJFf0d"
          }


          var res = await sendInit(url, sendata);
          if (res.token == 'forbidden') {
               context.commit('setToken', res.token);
          }
          else {
               console.log("guarda cookie");
               console.log('res.key', res.key);
               console.log('res.token', res.token);
               util.setCookie('init_', JSON.stringify(res))

               console.log('res', res)
               _TOKEN = res.token;
               var afiliado_ocupacion = res.key.substr(__JT_BO, __AR_XD)

               _ENC = enc(afiliado_ocupacion);

          }
     },
     get: async (context, phone) => {

          var res = await sendEnc(URL_GET, { phone }, () => context.commit('renewToken'));

          //          var res = {"success":1,"result":"nouser","err":""};
          //          var res = {"success":1,"result":"f17ed39069b9086bdfe1bc541ea4cc248e65f6f6827cb52c53fc0a545122b93419328c54790975a58df6de4d3a64b0da3Du17efGjvp0ez/Pjs8Sg6F2bq8hOYhh13NsZf22+aNTHljIBeRrpB9rFy/YHaCY/M/9g5YINCKtyhvyEqxgEOO1n2mx2Ls9ztHMq74eN4mWPgtVrWyVpfbGvWuA+oEKqLXzuSv6111KUC9jBVdp4mMdJdseSGO4nNRGskG9vkvBeGQR4AouzMpGvYE2ojHJIv91WRs1fc3eWbgB1OK/GcbTV1ryunNUg7veslF5Tr+7h27WVdP4XUpfpt19WKq0v9RTqYygpWF9R3HdEXA4qV2gvwZoGckNwVwn3DAw3y93RY1SVmgW7P0ugH4k7jzTl5J8wVpeRHGuV5m3SS4JlTvdBCNSGOswPApKZs0yv/3SPT5egMGzbio7LrXOcbEEudWkHgWNendGY4hfKSPu/L+Q8qR5Y/1xrRjARfDMuKcokWHm92ZRIt3mTNcrPjYkMgY7AleuUwEfBpEOqBz/4qlPzbwyew/60Rh1Zap22J0XcK2cc99029EznszkTnmAm1zQaojjG97W4EOyMi347IW/8Xohq+97SR6aEztSVsjhpWmpIVnIC2LzpLpE7YHIHV191GpbYS3QL2C/57/soG7hj5fcc8YpsLjwBWP37bSGQKUb7LL6I86teGpk8oF8cAIgfMhvxFx7IZhEh7XVYkGZAOMeN1/oEnxZQYsZaPzFdF9hK6fhy/1A0HR3YYwaUdBldZcwRaWmQzvmI+ydGw0Mq+DuX8MXfP3H50Acyo8ruQXzIaDY89b2W8ZVHIvp4qJ40m9Om318Vu8ObyBvYDLbb1PBNjO/6RvlxNbRzbrfGQw9A/lQDSMcJMdJUQDjUIO09PB+H2rKnP6xO3iclWVyeua1tgEyzOweb0SC2dHM4zjiEoOK1GfhN7Y5HUSrYTdo7oZnttGVU5k80ZiLK5U705tjTeuSGHoKGNa5eJRD08+A2QJN69FaeF6wakBi6iYUdcYNAp2VdptKFhnqecRBupIfd9Ifl9pHzFGk6dsorFvRixFvXTtvV2BU3Sj5iQc+sxVx0tAU2d3vlaOfnMCdahWd8x2sfQAMw3UqE6c8P7/n+pJSxXZsaz+/Kw40","err":""};
          res = JSON.parse(_ENC.decrypt(res.result));

          console.log('res', res);
          if (res.block == 1) {
               console.log('resresresget', res);

               context.commit('logged', -3);
          }
          else {
               util.setCookie('logged_', JSON.stringify(res))
               context.commit('setUser', res);
               context.commit('logged', res.registered);
          }

     },
     testPass: async (context, { movil, password }) => {
          var r = await sendEnc(URL_GET_PASS, { movil, password });
          var res = JSON.parse(_ENC.decrypt(r.result));

          console.log('res', res);
          if (res.access == 1) {

               context.commit('logged', 2);
          }
          else {
               context.commit('logged', -2);
          }
     },
     sendPass: async (context, { movil, password }) => {
          await sendEnc(URL_SEND_PASS, { movil, password });
          context.commit('logged', 2);
     },
     blockPass: async (context, { movil, password }) => {
          await sendEnc(URL_BLOCK_PASS, { movil, password });
          //context.commit('logged', 0);
     },
     testCode: async (context, { phone, code }) => {
          var res = await sendEnc(URL_CODE_TEST, { phone, code });
          context.commit('confirmCode', res['result']);
     },
     loadUserLogger(context) {
          var user = util.getCookie('logged_');
          if (user.length > 3) {
               console.log('uselogged_r', user);
               context.commit('setUser', JSON.parse(user));
          }
     }
}



export default {
     namespaced: true,
     state, getters, actions, mutations
}





function sendInit(url, data) {
     var senddata = JSON.stringify(data);
     /*return $.ajax({
          url: url,
          type: 'post',
          headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
               'x-api-key': _KPA
          },
          crossDomain: true,
          contentType: 'application/json; charset=UTF-8',
          dataType: "json",
          data: senddata
     });

     var senddata = _ENC.encrypt(JSON.stringify(data));*/
     console.log('senddata', url, senddata);
     return ajaxPost(url, senddata, {
          'Content-Type': 'application/x-www-form-urlencoded',
          'x-api-key': _KPA
     });
}

async function sendEnc(url, data, callback) {
     var senddata = _ENC.encrypt(JSON.stringify(data));
     try {
          var res = await ajaxPost(url, senddata, {
               'Content-Type': 'application/x-www-form-urlencoded',
               'Authorization': _TOKEN
          });

          if (res['success'] == 0) {
               setTimeout(async function () {
                    console.log('retry..');
                    return await sendEnc(url, data)
               }, 5000);
          }
          return res;

     }
     catch (e) {
          console.log('sendenc');
          callback();
     }

}

function fetch(url, data, vars) {
     /*
          vars = vars || {};
          for (var i in vars) {
               url = url.replace(':' + i, vars[i])
          }
     
          console.log('url', url);
     
          return $.ajax({
               url: url,
               type: "get",
               data: data,
               crossDomain: true,
     
          });*/
}


function ajaxPost(url, data, header) {

     return new Promise(function (res, rej) {
          header = header || {};
          var request = new XMLHttpRequest();

          request.open('POST', url, true);



          for (var i in header) {
               request.setRequestHeader(i, header[i]);
          }

          request.onreadystatechange = function (status, x) {
               console.log('statxxxus', JSON.stringify(status));
               console.log('xx', x);

               if (status.status == 401) {
                    navigator.notification.alert("Your login/password is incorrect", null, "Authentication Error", "OK");
                    return;
               }
          }
          request.onload = function () {
               console.log('status', this.status);
               if (this.status >= 200 && this.status < 500) {
                    // Success!
                    var resp = JSON.parse(this.response);
                    console.log('resp', resp);
               } else {
                    // We reached our target server, but it returned an error
               }
               res(JSON.parse(this.response));
          };

          request.onerror = function (e) {
               console.log('onerror');
               console.log("e", e, e.stack);
               // There was a connection error of some sort
               util.setCookie('init_', '', 0)
               rej(e)
          };
          request.send(data);
     })



}