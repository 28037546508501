<template>
	<div class="text-center">
			<v-card>
				<div class="text-center">
					<v-dialog persistent v-model="manual" max-width="600px">
						<v-card>
							<v-card-title>Establecer coordenadas manual
							</v-card-title>
							<v-card-text>
								<v-text-field outlined label="lat" v-model="lat"></v-text-field>
								<v-text-field outlined label="lng" v-model="lng"></v-text-field>
							</v-card-text>
							<v-card-actions>
								<v-btn class="primary" @click="setMark">Establecer cordenada</v-btn>
								<v-btn text @click="manual=false">Cancelar</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</div>
				<v-card-title ><v-btn @click="manual=true">{{lat.toString().substr(0,10)}} {{lng.toString().substr(0,10)}}</v-btn></v-card-title>
				<v-card-text>
					<GmapMap
						:center="center"
						:zoom="16"
						map-type-id="terrain"
						@click="addMarker"
						style="width: 100%; height: 100%"
					>
						<GmapMarker
							:key="index"
							v-for="(m, index) in markers"
							:position="m.position"
							:clickable="true"
							:draggable="true"
							@click="center=m.position"
						/>
					</GmapMap>
				</v-card-text>
				<v-card-actions>
					<v-row>
						<v-col cols="12">
							<v-btn @click="save" class="primary">Guardar</v-btn>
							<v-btn @click="close" class="ml-5" text>Cerrar</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
	</div>
</template>

<script>
export default {
	props: ["afiliado"],
	data() {
		return {
			lat: "",
			lng: "",
			manual: false,
			dialog: true,
			afil: {},
			markers: [],
			center: {
				lat: -32.90353760139466,
				lng: -68.84545243605052
			}
		};
	},
	mounted() {
		this.afil = this.afiliado;
		if (typeof this.afil.pos["lat"] !== 'undefined') {
			var marker = new google.maps.LatLng(
				this.afil.pos["lat"],
				this.afil.pos["lng"]
			);
			this.center = this.afil.pos;
			this.addMarker({ latLng: marker });
		}
	},
	methods: {
		setMark() {
               var lat = parseFloat(this.lat);
               var lng = parseFloat( this.lng);
               var marker = new google.maps.LatLng(lat, lng);
               this.center = {lat:lat, lng:lng};
			this.addMarker({ latLng: marker });
		},
		async save() {
			if (this.markers.length) {
				this.$store.commit("iface/setWait", { wait: true });
				var opt = {
					fields: {
						pos: JSON.stringify(this.markers[0]["position"])
					},
					id: this.afiliado.id
				};
				await this.$store.dispatch(
					"afiliaciones/updateAfiliacionSaveRds",
					opt
				);
				this.$store.commit("iface/setWait", { wait: false });
				this.$emit("close", true);
			}
		},
		close() {
			this.$emit("close");
		},
		addMarker(point) {
			console.log("point", point);
			var position = {
				position: {
					lat: parseFloat(point.latLng.lat()),
					lng: parseFloat(point.latLng.lng())
				}
			};
			console.log("point", position);
			this.lat = position.position.lat;
			this.lng = position.position.lng;
			this.markers = [position];
		}
	}
};
</script>

<style scoped>
</style>
