<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500" :fullscreen="isMobile">
			<v-card>
				<v-card-title class="headline blue darken-2">{{contacto.name || 'Nombre de Afiliado'}}</v-card-title>

				<v-card-text style="height: 410px;">
					<v-container>
						<v-row>
							<v-col cols="12">
								Enviar Whatsapp:
								<a
									target="_blank"
									:href="'https://api.whatsapp.com/send?phone=549'+contacto.phone"
								>{{contacto.phone}}</a>

								<v-text-field dense placeholder="Móvil/Whatsapp" outlined v-model="contacto.phone" />
							</v-col>
							<v-col cols="12">
								<v-text-field dense outlined placeholder="Email" v-model="contacto.mail" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Nombre:
								<v-text-field dense outlined v-model="contacto.nombre" />
							</v-col>
							<v-col cols="12">
								Apellido:
								<v-text-field dense outlined v-model="contacto.apellido" />
							</v-col>
							<v-col cols="12">
								Fecha de nacimiento:
								<v-text-field type="date" dense outlined v-model="contacto.nacimiento_fecha" />
							</v-col>
							<v-col cols="12">
								Lugar de nacimiento
								<v-text-field dense outlined v-model="contacto.nacimiento_lugar" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								DNI
								<v-text-field type="number" dense outlined v-model="contacto.dni" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Profesion
								<v-text-field dense outlined v-model="contacto.profesion" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Estado Civil
								<v-text-field dense outlined v-model="contacto.estado_civil" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="8">
								Calle
								<v-text-field dense outlined v-model="contacto.calle" />
							</v-col>
							<v-col cols="4">
								Numero
								<v-text-field dense outlined v-model="contacto.numero" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="3">
								Piso
								<v-text-field dense outlined v-model="contacto.piso" />
							</v-col>
							<v-col cols="3">
								Num Dep
								<v-text-field dense outlined v-model="contacto.depto" />
							</v-col>
							<v-col cols="6">
								Localidad
								<v-text-field dense outlined v-model="contacto.localidad" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-select
									dense
									outlined
									:items="departamentos"
									label="Departamento"
									v-model="contacto.departamento"
								></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Apellido Padre
								<v-text-field dense outlined v-model="contacto.padre_apellido" />
							</v-col>
							<v-col cols="12">
								Nombre Padre
								<v-text-field dense outlined v-model="contacto.padre_nombre" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Apellido Madre
								<v-text-field dense outlined v-model="contacto.madre_apellido" />
							</v-col>
							<v-col cols="12">
								Nombre Madre
								<v-text-field dense outlined v-model="contacto.madre_nombre" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Detalle:
								<v-textarea dense height="150px" fixed outlined v-model="contacto.detail"></v-textarea>
							</v-col>
						</v-row>
						<div class="row">
							<div class="col-12">
								<v-responsive width="100%" max-width="300px">
									<h5 style="text-align: center;margin-bottom: -7px;" @click="$refs.ff.uploadFoto()">Foto de frente de DNI</h5>
									<file-upload
                                                  ref="ff"
										id="file_inst_1"
										:files="front.files"
										:path="front.path"
										:prefix="front.prefix"
										:bucket="front.bucket"
										:commit="front.commit"
										@uploaded="getImageFotoFront"
										@removed="removeImagesFront"
										@updateFile="updateFileFront"
									>
										<template v-slot:upload>
											<v-card width="100%" max-width="360px" flat class="dotted">
												<v-responsive height="150px">
													<v-img src="/iconcloud.png" height="100%" contain></v-img>
												</v-responsive>
											</v-card>
										</template>
									</file-upload>
								</v-responsive>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<v-responsive max-width="300px" min-width="300px">
									<h5 style="text-align: center;margin-bottom: -7px;" @click="$refs.fb.uploadFoto()">Foto de Atrás de DNI</h5>
									<file-upload
                                                  ref="fb"
										id="file_inst_2"
										:files="back.files"
										:path="back.path"
										:prefix="back.prefix"
										:bucket="back.bucket"
										:commit="back.commit"
										@uploaded="getImageFotoBack"
										@removed="removeImagesBack"
										@updateFile="updateFileBack"
									>
										<template v-slot:upload>
											<v-card width="100%" max-width="360px" flat class="dotted">
												<v-responsive height="150px">
													<v-img src="/iconcloud.png" height="100%" contain></v-img>
												</v-responsive>
											</v-card>
										</template>
									</file-upload>
								</v-responsive>
							</div>
						</div>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="commitSave">Guardar</v-btn>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import contact_formVue from './contact_form.vue';
import FileUpload from "./fus.vue";

var dep = "Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle"
	.split(",")
	.sort();

export default {
	components: { FileUpload },
	props: {
		data: {
			default: false
		}
	},
	data() {
		return {
			picload: 0,
			file1: false,
			file2: false,
			front: {
				bucket: "plafiliaciones",
				path: "images",
				prefix: "afi_f_",
				files: [],
				commit: 0
			},
			back: {
				bucket: "plafiliaciones",
				path: "images",
				prefix: "afi_b_",
				files: [],
				commit: 0
			},

			departamentos: dep,
			estado_descripcion: "",
			estado: "",
			contacto: {
				phone: "",
				mail: "",
				nombre: "",
				apellido: "",
				nacimiento_fecha: "",
				nacimiento_lugar: "",
				dni: "",
				profesion: "",
				estado_civil: "",
				calle: "",
				numero: "",
				piso: "",
				depto: "",
				localidad: "",
				departamento: "",
				padre_apellido: "",
				padre_nombre: "",
				madre_apellido: "",
				madre_nombre: "",
				detail: "",
				name: ""
			},
			items: [
				"Sin Contactar",
				"Contactado desactivado",
				"Contactado activo",
				"datos falsos/descartar"
			],
               dialog: true,
               dni_orig:0,
		};
	},
	computed: {
		isMobile() {
			return (
				this.$vuetify.breakpoint.mobile || window.innerWidth < 700
			);
		}
	},
	watch: {
		data: {
			handler() {
				this.fillData(this.data);
			},
			deep: true,
			immediate: true
		},
		picload(val) {
			console.log("picval", val);
			if (val > 1) {
				this.picload = 0;
				this.save();
			}
		}
	},
	mounted() {
		this.picload = 0;
		console.log("mounted");
		//	this.fillData(false);
		this.$store.commit("iface/setWait", { wait: false });
	},
	methods: {
		fillData(data) {
			if (data) {
				console.log("filldata data data", data);
				this.contacto.phone = "";
				this.contacto.mail = "";
				this.dni_orig = "0";
				this.contacto.dni = "";
				this.contacto.nacimiento_fecha = "";
				this.contacto.apellido = "";
				this.contacto.nombre = "";
				this.contacto.nacimiento_lugar = "";
				this.contacto.profesion = "";
				this.contacto.estado_civil = "";
				this.contacto.calle = "";
				this.contacto.numero = "";
				this.contacto.piso = "";
				this.contacto.depto = "";
				this.contacto.localidad = "";
				this.contacto.departamento = "";
				this.contacto.padre_apellido = "";
				this.contacto.padre_nombre = "";
				this.contacto.madre_apellido = "";
				this.contacto.madre_nombre = "";
				var contacto = JSON.parse(JSON.stringify(data));
				var keys = Object.keys(contacto)
				for(var i = 0 ; i < keys.length ; i++){
					this.contacto[keys[i]] = contacto[keys[i]];
				}

                    this.dni_orig = this.contacto['dni'];
				console.log("fillData", data);

				this.front.files = [data["file1"]];
				this.back.files = [data["file2"] ];
			} else {
				this.contacto.phone = "";
				this.contacto.mail = "";
				this.contacto.dni = "";
				this.contacto.nacimiento_fecha = "";
				this.contacto.apellido = "";
				this.contacto.nombre = "";
				this.contacto.nacimiento_lugar = "";
				this.contacto.profesion = "";
				this.contacto.estado_civil = "";
				this.contacto.calle = "";
				this.contacto.numero = "";
				this.contacto.piso = "";
				this.contacto.depto = "";
				this.contacto.localidad = "";
				this.contacto.departamento = "";
				this.contacto.padre_apellido = "";
				this.contacto.padre_nombre = "";
				this.contacto.madre_apellido = "";
                    this.contacto.madre_nombre = "";
                    this.dni_orig = "0";
			}
		},
		updateFileFront(file) {
			this.foto1 = file;
		},
		updateFileBack(file) {
			this.foto2 = file;
		},

		//upload fotos
		getImageFotoFront(files) {
			this.picload++;
			this.file1 = files[0];
		},
		removeImagesFront() {},
		removeImagesBack() {},
		getImageFotoBack(files) {
			this.picload++;
			this.file2 = files[0];
		},
		commitSave() {
			//alert(this.valid);		return;
			//this.saving = true;
			//this.$store.commit("iface/setWait", { wait: true, text: "" });
			this.$store.commit("iface/setWait", { wait: true, text: "" });

			this.front.commit++;
			this.back.commit++;
		},
		async save() {
			this.$store.commit("iface/setWait", { wait: true });
			var afiliacion = {
                    dni: this.contacto.dni,
                    dni_orig : this.dni_orig,
				movil: this.contacto.phone,
				mail: this.contacto.mail,
				data: {
					nacimiento_fecha: this.contacto.nacimiento_fecha,
					apellido: this.contacto.apellido,
					nombre: this.contacto.nombre,
					nacimiento_lugar: this.contacto.nacimiento_lugar,
					profesion: this.contacto.profesion,
					estado_civil: this.contacto.estado_civil,
					calle: this.contacto.calle,
					numero: this.contacto.numero,
					piso: this.contacto.piso,
					depto: this.contacto.depto,
					localidad: this.contacto.localidad,
					departamento: this.contacto.departamento,
					padre_apellido: this.contacto.padre_apellido,
					padre_nombre: this.contacto.padre_nombre,
					madre_apellido: this.contacto.madre_apellido,
					madre_nombre: this.contacto.madre_nombre,
					file1: this.file1,
                         file2: this.file2,
					ref: ""
				}
			};

			console.log('afiliacion:',afiliacion);
			
			this.picload = 0;
			var res = await this.$store.dispatch(
				"afiliaciones/saveAfiliacion",
				afiliacion
               );

               if (!res){
                    alert("EL NUMERO DE DNI QUE HAS INGRESADO YA SE ENCUENTRA EN USO");
               }
               
               this.$store.commit("iface/setWait", { wait: false });
			
			this.$emit("close", true);
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>