<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500" :fullscreen="isMobile">
			<v-card>
				<v-card-title class="headline grey lighten-2">{{contacto.name || 'Nombre de contacto'}}</v-card-title>

				<v-card-text style="height: 80hv;">
					<v-container>
						<v-row>
							<v-col cols="12">
								Enviar Whatsapp:
								<a
									target="_blank"
									:href="'https://api.whatsapp.com/send?phone=549'+contacto.phone"
								>{{contacto.phone}}</a>

								<v-text-field dense outlined v-model="contacto.phone" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								Nombre:
								<v-text-field dense outlined v-model="contacto.name" />
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								Detalle:
								<v-textarea dense height="150px" fixed outlined v-model="contacto.detail"></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-select
									dense
									outlined
									:items="departamentos"
									label="Departamento"
									v-model="contacto.departamento"
								></v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="guardar">Guardar</v-btn>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
var dep = "Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle"
	.split(",")
	.sort();

export default {
	data() {
		return {
			departamentos: dep,
			estado_descripcion: "",
			estado: "",
			contacto: {
     			departamento: "",
				phone: "",
				detail: "",
				name: ""
			},
			items: [
				"Sin Contactar",
				"Contactado desactivado",
				"Contactado activo",
				"datos falsos/descartar"
			],
			dialog: true
		};
     },
     computed:{
          isMobile(){
               return this.$vuetify.breakpoint.mobile || window.innerWidth < 700
          }
     },
	mounted() {
		this.$store.commit("iface/setWait", { wait: false });
		console.log("this.contacto", this.contacto);
	},
	methods: {
		async guardar() {
			this.$store.commit("iface/setWait", { wait: true });
			var opt = {
				nombre: this.contacto.name,
				telefono: this.contacto.phone,
				detalle: this.contacto.detail,
				departamento: this.contacto.departamento
			};
			console.log("opt", opt);
			await this.$store.dispatch(
				"afiliaciones/insertContactoSaveRds",
				opt
			);
			this.$store.commit("iface/setWait", { wait: false });
			this.$emit("close", true);
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>