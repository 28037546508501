import Vue from 'vue'
import Vuex from 'vuex'
import afiliaciones from './store_afiliaciones.js'
import iface from './store_interface.js'
import users from './store_users.js'
import post from './store_post.js'
import front from './store_front.js'



Vue.use(Vuex)
export default new Vuex.Store({
     modules: {
       afiliaciones,
       iface,
       post,
       users,
       front,
     }
});