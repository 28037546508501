<template>
	<div class="text-center">
		<v-dialog persistent v-model="dialog" scrollable width="500" :fullscreen="isMobile">
			<v-card>
				<v-card-title class="headline grey lighten-2">{{contacto.name}}</v-card-title>

				<v-card-text style="height: 80hv;">
					<v-container>
						<v-row>
							<v-col cols="12">
								Enviar Whatsapp:
								<a
									target="_blank"
									:href="'https://api.whatsapp.com/send?phone=549'+contacto.phone"
								>{{contacto.phone}}</a> <p>{{contacto.fecha_ts}}</p>
							</v-col>
						</v-row>
                                   <v-divider inset></v-divider>

						<v-row>
							<v-col cols="12">
								<p>{{contacto.detail}}</p>
							</v-col>
                                   <v-divider inset></v-divider>
							<v-col cols="12">
								<v-alert
									type="warning"
								>El campo "observaciones" no es para responder al contacto. Si te contactaste con esta persona podés dejar las observaciones del caso ("no me atendió", "hay que contactarlo urgente", etc)</v-alert>

								<v-textarea v-model="estado_descripcion" outlined label="Observaciones "></v-textarea>
							</v-col>
							<v-col cols="12">
								<v-select label="seleccionar" v-model="estado" outlined :items="items"></v-select>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" @click="guardar">Guardar</v-btn>
					<v-btn color="primary" text @click="close">Cerrar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
var dep = "Godoy Cruz,Ciudad,Guaymallén,Las Heras,Maipú,Luján,San Rafael,General Alvear,Malargue,Tunuyán,Tupungato,San Carlos,Junín,Santa Rosa,La Paz,San Martín,Rivadavia,Lavalle"
	.split(",")
	.sort();

export default {
     props: ["contacto"],
     computed:{
          isMobile(){
               return this.$vuetify.breakpoint.mobile || window.innerWidth < 700
          }
     },
	data() {
		return {
			estado_descripcion: "",
			estado: "",
			items: [
				"Sin Contactar",
				"Contactado desactivado",
				"Contactado activo",
				"datos falsos/descartar"
			],
			dialog: true
		};
	},
	mounted() {
		this.$store.commit("iface/setWait", { wait: false });

		console.log("this.contacto", this.contacto);

		this.estado_descripcion = this.contacto.ed;
		this.estado = this.contacto.estado || "";
	},
	methods: {
		async guardar() {
			this.$store.commit("iface/setWait", { wait: true });
			var opt = {
				fields: {
					ed: this.estado_descripcion,
					estado: this.estado
				},
				id: this.contacto.id
               };
               console.log('opt',opt);
			await this.$store.dispatch(
				"afiliaciones/updateContactoSaveRds",
				opt
			);
			this.$store.commit("iface/setWait", { wait: false });
			this.$emit("close", true);
		},
		close() {
			this.$emit("close", false);
		}
	}
};
</script>

<style >
.v-dialog__content.v-dialog__content--active {
}
</style>